    @media only screen and (max-width: 1400px) {}

    /*Small Desktop */
    @media only screen and (max-width: 1200px) {}


    @media only screen and (max-width: 1023px) and (min-width:992px) {}


    @media only screen and (max-width:992px) {}


    @media only screen and (max-width:767px) {
      .topStatsArea .bgWhite {
        padding: 10px 0px 30px 0px;
      }

      .biggerCountArea {
        font-size: 60px;
      }

      .smallerTitleArea {
        font-size: 16px;
      }

      .onBoardScanArea .onBoardScanInner .form-field.form-field2 {
        width: 100%;
      }

      .onBoardScanArea .onBoardScanInner .form-field.form-field2 input {
        width: 70%;
      }

      .onBoardScanArea .onBoardScanInner .form-field p {
        width: 12%;
      }
    }

    @media only screen and (max-width:650px) {
      .biggerCountArea {
        font-size: 60px;
      }
    }