.loginComponentMainDiv {
    height: 100vh;
    position: relative;
}

.loginComponentMainDiv .firstRowDiv {
    height: 68vh;
    background: #fafafa;
}

.loginComponentMainDiv .secondRowDiv {
    height: 32vh;
    background: #fafafa;
}

.loginComponentMainDiv .loginFormWithLogoDiv {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 20px 10px;
}

@media screen and (min-width: 1200px) {
    .loginComponentMainDiv .loginFormWithLogoDiv {
        min-width: 1020px;
    }
}

.loginComponentMainDiv .loginFormWithLogoDiv .logoDiv {
    text-align: center;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv {
    margin: 2rem 0;
    background: #FFFFFF;
    border-radius: 0.5rem;
    padding: 2.5rem;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv .loginText {
    color: #394584;
    font-size: 1.65rem;
    font-weight: 600;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv .loginForm .eachElement {
    margin: 1rem 0;
    position: relative;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv .loginForm .eachElement img {
    position: absolute;
    top: 2.5rem;
    cursor: pointer;
    right: 0.5rem;
    width: 25px;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv .loginForm .eachElement label {
    color: #394584;
    display: block;
    font-size: 1rem;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv .loginForm .eachElement input {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #8898aa;
    background-color: #fff;
    border: 1px solid #cad1d7;
    border-radius: 0.375rem;
    box-shadow: none;
    outline: none;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv .loginForm .eachElement input:focus {
    outline: none;
    color: #8898aa;
    background-color: #fff;
    border: 1px solid #cad1d7;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv .forgetPasswordText {
    color: #394584;
    font-size: 1rem;
    cursor: pointer;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv .forgetPasswordText:hover {
    text-decoration: underline;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv .loginFormButton {
    margin: 2rem auto 1rem;
    display: block;
    padding: 0.75rem;
    border: none;
    outline: none;
    border-radius: 0.35rem;
    text-align: center;
    background: #0E4ED3;
    min-width: 80%;
    color: #FFFFFF;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv .loginText {
    color: #394584;
    font-size: 1.65rem;
    font-weight: 600;
}

.loginFormMainDiv.loginFormMainDivMobile .loginSmallText {
    font-size: 14px;
    color: #9d9797;
    text-align: center;
}

.loginFormMainDiv.loginFormMainDivMobile label {
    font-size: 13px !important;
    position: absolute;
    top: -8px;
    background: #fff;
    left: 12px;
    padding: 0px 10px;
}

.loginFormMainDiv.loginFormMainDivMobile .loginFormWithLogoDiv .loginFormMainDiv .loginForm .eachElement img {
    top: 10px !important;
}

.loginFormMainDiv.loginFormMainDivMobile .loginForm .eachElement img {
    top: 10px !important;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv.loginFormMainDivMobile .loginForm .eachElement input {
    border-radius: 20px !important;
    padding: 10px 20px !important;
    min-height: 45px !important;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv.loginFormMainDivMobile .loginForm .eachElement input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
    box-shadow: 0 0 0px 1000px white inset !important;
    -webkit-text-fill-color: #000 !important;
    caret-color: #000;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv.loginFormMainDivMobile .loginFormButton {
    margin: 1.5rem auto 1rem;
    text-align: center;
    background: #0E4ED3;
    min-width: 80%;
    color: #FFFFFF;
    border-radius: 20px !important;
}

.loginFormMainDiv.loginFormMainDivMobile .dangerColor {
    color: red !important
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv.loginFormMainDivMobile .loginText {
    color: #394584;
    text-align: center;
    font-size: 26px !important;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv.loginFormMainDivMobile {
    background-color: transparent !important;
}