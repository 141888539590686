.logoutModal {
  background-color: #f9fafa;

  .logoutTitle {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.7;
    padding: 20px 0 10px;
  }

  .logoutDesc {
    font-size: 13px;
    font-weight: normal;
    text-align: center;
    padding: 10px 0 42px;

    span.counttimer {
      color: #f73e5d !important;
      font-weight: 700;
    }
  }

  .actionButtons {
    display: -webkit-box;
    border-top: 0.063rem solid #ebeced;
    text-align: center;
    cursor: pointer;
    float: left;
    width: 100%;

    .btnText {
      font-size: 1.125rem;
      display: block;
      text-align: center;
      text-decoration: none;
      line-height: 1.7;
      padding: .65em 0;
      width: 49.7%;
      color: #7c828a;
    }

    .okBtn {
      color: #f73e5d !important;
    }

    .borderRight {
      border-right: 0.063rem solid #ebeced;
    }
  }
}

.logoutModal .logoutDesc {
  font-size: 13px;
  font-weight: normal;
  text-align: center;
  padding: 10px 0 42px;
}

.main-content.side-content {
  -webkit-transition: margin-left 0.3s ease;
  transition: margin-left 0.3s ease;
}

.sidemenu-label {
  -webkit-transition: margin-left 0.45s ease;
  transition: margin-left 0.45s ease;
}

.side-menu .nav-link:hover {
  color: #ffffff;
}

.side-menu .nav-link:hover .sidemenu-label {
  margin-left: 5px;
  -webkit-transition: margin-left 0.45s ease;
  transition: margin-left 0.45s ease;
}

.side-menu .nav-item {
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
  margin: 6px 0;
}

.side-menu .nav-sub {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all 0.9s;
  transition: all 0.9s;
}

.side-menu .nav-item.show>.nav-sub {
  max-height: 100vh;
  -webkit-transition: all 2s;
  transition: all 2s;
}

.side-menu .nav-sub-link {
  -webkit-transition: all .25s;
  transition: all .25s;
}

.iconbtn {
  display: none;
}

.side-menu i.angle {
  -webkit-transform-origin: center;
  transform-origin: center;
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 12px;
}

.side-menu .nav-item.show i.angle {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 12px;
}

.side-badge,
.main-sidebar-open.main-sidebar-hide .side-badge {
  position: absolute;
  top: 12px;
  right: 18px;
  border-radius: 50px;
}

.main-sidebar-hide .side-badge {
  position: absolute;
  top: 0px;
  right: 27px;
  border-radius: 50px;
  z-index: 4;
}

.sidemenu-logo {
  padding: 13.4px 21px;
  border-bottom: 1px solid rgba(240, 240, 255, 0.1);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.side-menu .header-brand-img {
  margin-top: 0 !important;
  outline: 0;
}

.main-footer {
  -webkit-transition: margin-left 0.3s ease;
  transition: margin-left 0.3s ease;
}

/* ###### Sidebar  ###### */
@media (max-width: 991.98px) {
  .main-sidebar-show {
    overflow: inherit;
  }

  .main-sidebar-show .main-sidebar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    visibility: visible;
    -webkit-transition: left 0.3s ease, width 0.3s ease;
    transition: left 0.3s ease, width 0.3s ease;
  }

  .main-sidebar-show .main-navbar-backdrop {
    opacity: 1;
  }
}

@media (min-width: 992px) {
  .main-sidebar-hide .main-sidebar {
    display: none;
  }

  .main-sidebar-hide .main-sidebar-sticky+.main-content {
    margin-left: 0;
  }
}

.main-body-sidebar {
  display: block;
}

.main-body-sidebar .main-content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.main-body-sidebar .main-content-header {
  height: auto;
  padding: 20px;
  margin-bottom: 0;
}

.main-body-sidebar .main-content-body {
  padding: 0 20px 20px;
}

.main-body-sidebar .main-footer {
  margin-top: auto;
}

@media (min-width: 992px) {
  .main-body-sidebar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  .main-body-sidebar .main-header .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.main-sidebar {
  width: 240px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-right: 0;
  -webkit-transform: translateX(-240px);
  transform: translateX(-240px);
  visibility: hidden;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition: left 0.4s ease, width 0.4s ease;
  transition: left 0.4s ease, width 0.4s ease;
}

@media (prefers-reduced-motion: reduce) {
  .main-sidebar {
    -webkit-transition: none;
    transition: none;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .main-sidebar {
    width: 220px;
  }
}

@media (max-width: 991.98px) {
  .main-sidebar {
    position: fixed;
    top: 0px !important;
    left: 0;
    bottom: 0;
    z-index: 2;
    background-color: #ffffff;
  }
}

@media (min-width: 992px) {
  .main-sidebar {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transform: none;
    transform: none;
    z-index: 999;
    border-right-width: 1px;
    visibility: visible;
  }
}

.main-sidebar-sticky {
  position: fixed;
  top: 0px;
  left: 0;
  bottom: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #25233c;
}

.main-sidebar-body .nav-item.active .nav-link:before {
  content: "";
  position: absolute;
  top: -30px;
  z-index: 9;
  right: 0px;
  bottom: 0;
  height: 30px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom: transparent;
  border-radius: 0px 0px 48px 0px;
}

.main-sidebar-body .nav-item.active .nav-link:after {
  content: "";
  position: absolute;
  top: 43px;
  z-index: 0;
  right: 0px;
  bottom: 0;
  height: 30px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom: transparent;
  border-radius: 0px 48px 0px 0px;
}

.main-sidebar-body .nav-item.active .nav-link .shape1 {
  position: absolute;
  top: -30px;
  width: 19px;
  height: 60px;
  right: 0px;
}

.main-sidebar-body .nav-item.active .nav-link .shape2 {
  position: absolute;
  top: 35px;
  width: 19px;
  height: 30px;
  right: 0px;
}

.option-dots {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: transparent;
  margin: 0 auto;
  text-align: center;
  line-height: 40px;
  display: block;
}

.main-sidebar-body li.nav-item.active .nav-link {
  background-color: #eaedf7;
  position: relative;
  z-index: 1;
  text-align: center;
  border-radius: 30px 0 0 30px;
  margin-left: 0;
  border: 0px solid transparent;
  border-right: 0px;
  width: inherit;
}

.main-sidebar-body .nav-item.active .nav-link:before,
.main-sidebar-body .nav-item.active .nav-link:after {
  border-right: 20px solid #25233c;
}

.main-sidebar-body .nav-item.active .nav-link .shape1,
.main-sidebar-body .nav-item.active .nav-link .shape2 {
  background: #eaedf7;
}

@media (min-width: 992px) {
  .main-sidebar-sticky+.main-content {
    margin-left: 220px;
  }
}

@media (min-width: 1200px) {
  .main-sidebar-sticky+.main-content {
    margin-left: 240px;
  }
}

.main-sidebar-header {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 64px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 20px;
}

.main-sidebar-loggedin {
  padding: 10px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.main-sidebar-loggedin .main-img-user {
  width: 36px;
  height: 36px;
}

.main-sidebar-loggedin .media-body {
  margin-left: 12px;
}

.main-sidebar-loggedin .media-body h6 {
  font-weight: 700;
  font-size: 14px;
  color: #8f9cc0;
  margin-bottom: 2px;
}

.main-sidebar-loggedin .media-body span {
  display: block;
  color: #a8afc7;
}

.main-sidebar-body {
  padding: 0;
}

@media (min-width: 1200px) {
  .main-sidebar-body {
    padding: 12px 0px 12px 0px;
  }
}

.main-body .main-sidebar-body .nav {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0 0 0 15px;
  margin: 0 auto;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 0;
}

.nav-header {
  margin: 0 0 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.main-sidebar-body .nav-label {
  text-transform: uppercase;
  font-size: 11.49px;
  font-weight: 500;
  letter-spacing: .5px;
  color: rgba(255, 255, 255, 0.3);
  padding: 10px 0px 0 0;
}

.second-sidemenu .nav-label {
  text-transform: uppercase;
  font-size: 11.49px;
  font-weight: 500;
  letter-spacing: .5px;
  color: #77778c;
  padding: 10px 0px 0 0;
}

.second-sidemenu .nav-header li {
  list-style-type: none;
}

.main-sidebar-body .sub-txt {
  font-size: 10.9px;
  padding: 0 0px 0 0;
  color: #9b9ca3;
  margin-bottom: 0;
  position: absolute;
  margin-top: -1px;
}

.second-sidemenu .sub-txt {
  font-size: 10.9px;
  padding: 0 0px 0 0;
  color: #8d95b1;
  margin-bottom: 10px;
}

.main-sidebar-body .nav-item {
  position: relative;
  display: block;
}

.main-sidebar-body .nav-item+.nav-item {
  margin-top: -1px;
}

.main-sidebar-body .nav-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 4px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
}

.main-sidebar-body .nav-link .sidemenu-icon {
  font-size: 17px;
  line-height: 0;
  margin-right: 10px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
}

.main-sidebar-body .nav-link i.typcn {
  line-height: .9;
}

.main-sidebar-body .nav-link i.typcn::before {
  width: auto;
}

.main-sidebar-body .nav-link.with-sub {
  position: relative;
}

.main-sidebar-body .nav-sub {
  list-style: none;
  padding: 0;
  margin-left: 1.2rem;
}

.main-sidebar-body .nav-sub .nav-sub-link:before {
  content: '\e048';
  font-family: 'typicons';
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  top: 8px;
  font-size: 12px;
  opacity: 0.4;
  margin-right: 22px;
  color: rgba(255, 255, 255, 0.8);
}

.main-sidebar-body .nav-sub-item .nav-sub {
  padding: 0 0 0 12px;
  border-left-width: 1px;
  margin-left: 0;
}

.main-sidebar-body .nav-sub-item .nav-sub .nav-sub-link {
  height: auto;
  padding: 7px 0;
}

.main-sidebar-body .nav-sub-item.active>.nav-sub-link {
  color: #c6c6e0;
}

.main-sidebar-body .nav-sub-item.show .nav-sub {
  display: block;
}

.main-sidebar-body .nav-sub-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.4);
  font-size: 13px;
  padding: 8px 0;
}

.main-sidebar-body .nav-sub-link.with-sub {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.main-sidebar-body .nav-sub-link.with-sub::after {
  content: '\f3d0';
  font-family: 'Ionicons';
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  margin-left: auto;
  color: #f0f0ff;
}

.main-sidebar-body .nav-sub-link+.nav-sub-link {
  border-top: 1px dotted #f0f0ff;
}

.main-sidebar-body li.active .sidemenu-icon {
  font-size: 18px;
  line-height: 0;
  margin-right: 10px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  color: #ffffff;
  position: relative;

}

.main-sidebar-body li .sidemenu-icon svg {
  width: 20px;
  fill: #fff6;
}

.main-sidebar-body li.active .sidemenu-icon svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  fill: #fff;
}

.main-sidebar-body li.active .sidemenu-icon {
  background: #e35a88;
}

.main-sidebar-body li.active .nav-link,
a:hover {
  color: #e35a88;
}

a {
  color: #e35a88;
}

@media (min-width: 992px) {
  .main-sidebar-body .nav-sub-link.with-sub::after {
    margin-left: 4px;
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .main-sidebar-hide .main-sidebar-body li.active:after {
    top: 43px;
  }

  .main-sidebar-hide .main-sidebar .main-sidebar-body {
    padding: 17px 0px 12px 0px;
  }

  .main-sidebar-hide .main-sidebar-body .nav-link .sidemenu-icon {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
    z-index: 1;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .main-sidebar-hide.main-sidebar-open .main-sidebar-body li.active {
    position: relative;
    z-index: 1;
    margin: 0 auto;
    text-align: center;
    border-radius: 30px 0 0 30px;
    margin-left: 0;
    border: 0px solid transparent;
    border-right: 0px;
    -webkit-transition: none;
    transition: none;
  }

  .main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-link .sidemenu-icon {
    font-size: 18px;
    line-height: 0;
    margin-right: 10px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .main-sidebar-hide.main-sidebar-open .main-sidebar-body li.active .sidemenu-icon {
    font-size: 18px;
    line-height: 0;
    margin-right: 10px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: #6259ca;
    text-align: center;
    border-radius: 50%;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    color: #ffffff;
  }

  .main-body.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 0 0 15px;
    text-align: inherit;
    margin: 0;
    -webkit-box-pack: inherit;
    -ms-flex-pack: inherit;
    justify-content: inherit;
  }

  .main-sidebar-hide .main-sidebar-body li.active {
    margin: 6px 0;
  }

  .main-sidebar-hide .main-sidebar-body li.active .sidemenu-icon {
    background: #6259ca;
    margin-left: 5px;
  }

  .main-sidebar-hide .main-sidebar-body li.nav-item.active .nav-link {
    width: inherit;
    -webkit-transition: margin-left 0.3s ease;
    transition: margin-left 0.3s ease;
  }

  .main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.active .nav-link:before {
    content: "";
    position: absolute;
    top: -30px;
    z-index: 9;
    right: 0px;
    bottom: 0;
    height: 30px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom: transparent;
    border-radius: 0px 0px 48px 0px;
  }

  .main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.active .nav-link:after {
    content: "";
    position: absolute;
    top: 43px;
    z-index: 0;
    right: 0px;
    bottom: 0;
    height: 30px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom: transparent;
    border-radius: 0px 48px 0px 0px;
  }
}

/* ###### Sidebar  ###### */


@media (min-width: 992px) {
  .side-header .main-logo {
    color: inherit;
    margin-right: 0;
    font-size: 1.25rem;
    white-space: nowrap;
    font-weight: 600;
    padding: 0;
    -webkit-transition: .3s opacity;
    transition: .3s opacity;
    line-height: 2.9rem;
    text-align: center;
    text-align: center;
  }

  .main-sidebar-hide .side-header,
  .main-sidebar-hide .main-footer {
    padding-left: 70px;
  }

  .main-body .side-header {
    position: fixed;
    width: 100%;
    z-index: 10 !important;
    border-bottom: 1px solid #e1e6f1;
    -webkit-box-shadow: -7.829px 11.607px 20px 0px rgba(144, 143, 160, 0.09);
    box-shadow: -7.829px 11.607px 20px 0px rgba(144, 143, 160, 0.09);
    left: 0;
    padding-left: 240px;
    background-color: #fff;
    top: 0;
  }

  .main-body.main-sidebar-hide .side-header {
    padding-left: 75px;
  }

  .main-sidebar-hide .sidemenu-logo {
    padding: 8px 6px 6px 6px;
    border-bottom: 1px solid rgba(240, 240, 255, 0.1);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 65px;
  }

  .main-sidebar-hide.main-sidebar-open .sidemenu-logo {
    padding: 13.4px;
    border-bottom: 1px solid rgba(240, 240, 255, 0.1);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .main-sidebar.side-menu {
    width: 240px;
  }

  .main-footer {
    margin-left: 0;
  }
}

@media (max-width: 992px) and (min-width: 767px) {
  .main-header-menu-icon {
    margin-left: 15px;
  }
}

@media (max-width: 767px) {
  .main-header-menu-icon {
    margin-left: 15px;
  }

  .side-header .icon-logo {
    margin-left: 0;
  }

  .main-sidebar-show .side-menu {
    top: 60px !important;
  }
}

@media (min-width: 768px) {

  /*main-sidebar-hide*/
  .main-body.main-sidebar-hide.main-sidebar-open .side-menu .nav-link {
    padding: 4px 0px !important;
  }

  .main-sidebar-hide .side-menu {
    left: 0;
    width: 80px;
    display: block;
    visibility: visible;
    -webkit-transform: none;
    transform: none;
  }

  .main-sidebar-hide .nav-label,
  .main-sidebar-hide .main-sidebar-body .sub-txt {
    display: none;
  }

  .main-sidebar-body .nav-label-1 {
    height: 28px;
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .5px;
    color: #ffffff;
    padding: 0;
    background: #07a7bb;
    width: 28px;
    border-radius: 50%;
    text-align: center;
    margin: 3px 11px;
    line-height: 2.1;
  }

  .main-sidebar-hide .nav-header {
    margin: 0;
  }

  .main-sidebar-hide.main-sidebar-open .nav-header {
    margin: 0 0 0 15px;
  }

  .main-sidebar-hide .sidemenu-label {
    display: none;
    font-size: 10px;
  }

  .main-sidebar-hide .nav-item {
    width: 100%;
  }

  .main-sidebar-hide .text-icon {
    display: none;
  }

  .main-sidebar-hide .iconbtn {
    display: block;
  }

  .main-sidebar-hide .main-sidebar-body i.angle {
    display: none;
  }

  .main-sidebar-hide .side-menu .nav-link {
    margin: 0 auto;
    padding: 4px 0;
    text-align: center;
    margin-bottom: 0;
  }

  .main-sidebar-hide .main-sidebar-body .nav-item {
    margin-top: -1px;
    text-align: center;
    margin: 6px 5px;
  }

  .main-sidebar-hide .main-sidebar-body .nav-item+.nav-item {
    margin-top: -1px;
    text-align: center;
  }

  .main-sidebar-hide .side-menu .main-logo .desktop-logo {
    display: none;
  }

  .main-sidebar-hide .side-menu .main-logo .icon-logo {
    display: block;
    margin-left: 10px;
  }

  .main-sidebar-hide .side-menu .header-brand-img {
    margin-top: 0 !important;
  }

  .main-sidebar-hide .main-sidebar-body .nav-item.show>.nav-sub {
    display: none;
  }

  .main-sidebar-hide .main-footer {
    margin-left: 0;
  }

  .main-sidebar-hide .side-menu .main-sidebar-body .nav-link i {
    font-size: 20px;
  }

  .main-sidebar-hide .side-menu i.angle {
    font-size: 12px !important;
  }

  /*main-sidebar-open*/
  .main-sidebar-open .side-menu {
    left: 0;
    width: 240px;
  }

  .main-sidebar-open .nav-label {
    display: block;
  }

  .main-sidebar-open .main-sidebar-body .sub-txt {
    display: block;
    font-size: 10.9px;
    padding: 0 0px 0 0;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 0;
  }

  .main-sidebar-open .main-sidebar-body .nav-label {
    text-transform: uppercase;
    font-size: 11.49px;
    font-weight: 500;
    letter-spacing: .5px;
    color: rgba(255, 255, 255, 0.3);
    padding: 10px 0px 0 0;
  }

  .main-sidebar-open .sidemenu-label,
  .main-sidebar-open .text-icon {
    display: block;
  }

  .main-sidebar-open .iconbtn {
    display: none;
  }

  .main-sidebar-open .main-sidebar-body i.angle {
    display: block;
  }

  .main-sidebar-open .side-menu .nav-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0px;
  }

  .main-sidebar-open .main-sidebar-body .side-menu .nav-link {
    margin-left: 10px;
  }

  .main-sidebar-open .sidemenu-label {
    font-size: 14px;
  }

  .main-sidebar-open .side-menu .main-logo .desktop-logo {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .main-sidebar-open .side-menu .main-logo .icon-logo {
    display: none;
  }

  .main-sidebar-open .main-sidebar-body .nav-item.show>.nav-sub {
    display: block;
  }

  .main-sidebar-open .side-menu .nav-item.show i.angle {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    position: absolute;
    top: 16px;
    right: 20px;
  }

  .main-sidebar-open .side-menu .main-sidebar-body .nav-link i {
    margin-right: 5px;
    font-size: 18px;
  }

  .main-sidebar-open .side-menu i.angle {
    font-size: 12px;
  }
}

.main-header .select2-container .select2-selection--single .select2-selection__rEndered {
  display: block;
  padding-left: 17px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.main-sidebar .nav-sub-item.active .nav-sub-link {
  color: #fff !important;
}

@media (min-width: 768px) {
  .color-leftmenu.main-sidebar-hide .side-menu .main-logo .desktop-logo {
    display: none !important;
  }

  .color-leftmenu.main-sidebar-hide .side-menu .main-logo .icon-logo {
    display: block !important;
    margin-left: 10px;
  }

  .leftmenu.color-leftmenu.main-sidebar-hide .header-brand-img.icon-logo.theme-logo {
    display: none !important;
  }

  .color-leftmenu.main-sidebar-hide .header-brand-img.desktop-logo.theme-logo,
  .color-leftmenu.main-sidebar-hide .header-brand-img icon-logo theme-logo {
    display: none !important;
  }

  .color-leftmenu.leftmenu.main-sidebar-hide.main-sidebar-open .header-brand-img.icon-logo {
    display: none !important;
  }

  .color-leftmenu.leftmenu.main-sidebar-hide.main-sidebar-open .header-brand-img.icon-logo.theme-logo {
    display: block !important;
    display: none !important;
  }

  .color-leftmenu.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .desktop-logo {
    display: block !important;
  }

  .color-leftmenu.main-sidebar-hide.main-sidebar-open .main-logo .header-brand-img.desktop-logo.theme-logo {
    display: none !important;
  }

  .light-leftmenu.leftmenu .header-brand-img.desktop-logo {
    display: none !important;
  }

  .light-leftmenu.leftmenu .header-brand-img.desktop-logo.theme-logo {
    display: block !important;
  }

  .leftmenu.light-leftmenu.main-sidebar-hide .header-brand-img.icon-logo {
    display: none !important;
  }

  .leftmenu.light-leftmenu.main-sidebar-hide .header-brand-img.desktop-logo {
    display: none !important;
  }

  .leftmenu.light-leftmenu.main-sidebar-hide .header-brand-img.desktop-logo.theme-logo {
    display: none !important;
  }

  .leftmenu.light-leftmenu.main-sidebar-hide .header-brand-img.icon-logo.theme-logo {
    display: block !important;
    margin-left: 10px;
    margin-right: 0;
  }

  .leftmenu.light-leftmenu.main-sidebar-hide.dark-theme .header-brand-img.icon-logo.theme-logo {
    display: block !important;
  }

  .leftmenu.light-leftmenu.main-sidebar-hide.dark-theme .header-brand-img.desktop-logo.theme-logo {
    display: none !important;
  }

  .light-leftmenu.leftmenu.main-sidebar-open .header-brand-img.desktop-logo.theme-logo {
    display: block !important;
  }

  .leftmenu.light-leftmenu.main-sidebar-hide.main-sidebar-open .header-brand-img.icon-logo.theme-logo {
    display: none !important;
  }

  .leftmenu.light-leftmenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-label {
    color: #c8c4db;
  }

  .leftmenu.light-leftmenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .sub-txt {
    color: #a2a2b9;
  }

  .leftmenu.light-leftmenu.main-sidebar-hide .sidemenu-logo {
    border-bottom: 1px solid #f0f0ff;
  }

  .main-body.leftmenu .sidemenu-logo {
    position: fixed;
    z-index: 9999;
    width: 240px;
    background: #25233c;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
  }

  .main-body.leftmenu .main-sidebar-body {
    margin-top: 65px;
  }

  .main-sidebar-hide .sidemenu-logo {
    position: fixed;
    z-index: 999;
    width: 80px !important;
    background: #25233c;
  }

  .main-sidebar-hide.main-sidebar-open .sidemenu-logo {
    width: 240px !important;
  }

  .main-sidebar-hide .main-sidebar-body {
    margin-top: 65px;
  }

  .dark-theme.main-body.leftmenu .sidemenu-logo {
    background: #0e0e23;
    border-right: 1px solid #2b2b3e;
  }

  .color-leftmenu.main-body.leftmenu .sidemenu-logo {
    background: #6259ca;
  }

  .dark-theme.light-leftmenu .sidemenu-logo {
    background: #fff;
  }

  .dark-theme.light-theme.main-body.leftmenu .sidemenu-logo {
    background: #0e0e23;
  }

  .dark-theme.color-leftmenu.main-body.leftmenu .sidemenu-logo {
    background: #6259ca;
  }

  .dark-theme.light-leftmenu.main-body.leftmenu .sidemenu-logo {
    background: #fff;
  }
}

*::-ms-backdrop,
.side-header {
  position: relative !important;
}

*::-ms-backdrop,
.main-sidebar-body .nav-sub .nav-sub-link:before {
  top: 0;
}

.side-menu .nav-sub-item.show>.nav-sub {
  max-height: 100vh;
}

.side-menu .nav-sub-item>.nav-sub {
  max-height: 0;
}

.main-sidebar-body .nav-sub-link.with-sub {
  position: relative;
  line-height: 2;
}

.main-sidebar-body .nav-sub-item .nav-sub-link.with-sub::after {
  display: none;
}

.side-menu .nav-item.show .nav-sub .nav-sub-link.with-sub i.angle {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.side-menu .nav-item.show .nav-sub .nav-sub-item.show .nav-sub-link.with-sub i.angle {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.side-menu .nav-item.show .nav-sub .nav-sub-item.show .nav-sub .nav-sub-link.with-sub i.angle {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.side-menu .nav-item.show .nav-sub .nav-sub-item.show .nav-sub .nav-sub-item.show .nav-sub-link.with-sub i.angle {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

/*# sourceMappingURL=sidemenu.css.map */