@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800);
@import url(https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Neonderthaw&family=Sen:wght@400;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Neonderthaw&family=Sen:wght@400;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.successToast{
  background: #00FF00 !important;
  color: #FFFFFF !important;
}

.errorToast{
  background: #ff0000 !important;
  color: #FFFFFF !important;
}

.ReactModal__Overlay{
  background: rgba(0,0,0,0.5) !important;
  box-shadow: none !important;
}

*{box-sizing: border-box;}
.ag-cell,.ag-body-viewport.ag-selectable.ag-layout-normal.ag-row-no-animation,.ag-body-viewport.ag-selectable.ag-layout-normal.ag-row-no-animation * {
  user-select: initial;
  -moz-user-select: text;
  -ms-user-select: text;
  -webkit-user-select: text;
}
.logoutModal {
  background-color: #f9fafa;

  .logoutTitle {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.7;
    padding: 20px 0 10px;
  }

  .logoutDesc {
    font-size: 13px;
    font-weight: normal;
    text-align: center;
    padding: 10px 0 42px;

    span.counttimer {
      color: #f73e5d !important;
      font-weight: 700;
    }
  }

  .actionButtons {
    display: -webkit-box;
    border-top: 0.063rem solid #ebeced;
    text-align: center;
    cursor: pointer;
    float: left;
    width: 100%;

    .btnText {
      font-size: 1.125rem;
      display: block;
      text-align: center;
      text-decoration: none;
      line-height: 1.7;
      padding: .65em 0;
      width: 49.7%;
      color: #7c828a;
    }

    .okBtn {
      color: #f73e5d !important;
    }

    .borderRight {
      border-right: 0.063rem solid #ebeced;
    }
  }
}

.logoutModal .logoutDesc {
  font-size: 13px;
  font-weight: normal;
  text-align: center;
  padding: 10px 0 42px;
}

.main-content.side-content {
  transition: margin-left 0.3s ease;
}

.sidemenu-label {
  transition: margin-left 0.45s ease;
}

.side-menu .nav-link:hover {
  color: #ffffff;
}

.side-menu .nav-link:hover .sidemenu-label {
  margin-left: 5px;
  transition: margin-left 0.45s ease;
}

.side-menu .nav-item {
  transition-duration: 0.25s;
  margin: 6px 0;
}

.side-menu .nav-sub {
  max-height: 0;
  overflow: hidden;
  transition: all 0.9s;
}

.side-menu .nav-item.show>.nav-sub {
  max-height: 100vh;
  transition: all 2s;
}

.side-menu .nav-sub-link {
  transition: all .25s;
}

.iconbtn {
  display: none;
}

.side-menu i.angle {
  transform-origin: center;
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 12px;
}

.side-menu .nav-item.show i.angle {
  transform: rotate(90deg);
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 12px;
}

.side-badge,
.main-sidebar-open.main-sidebar-hide .side-badge {
  position: absolute;
  top: 12px;
  right: 18px;
  border-radius: 50px;
}

.main-sidebar-hide .side-badge {
  position: absolute;
  top: 0px;
  right: 27px;
  border-radius: 50px;
  z-index: 4;
}

.sidemenu-logo {
  padding: 13.4px 21px;
  border-bottom: 1px solid rgba(240, 240, 255, 0.1);
  justify-content: center;
}

.side-menu .header-brand-img {
  margin-top: 0 !important;
  outline: 0;
}

.main-footer {
  transition: margin-left 0.3s ease;
}

/* ###### Sidebar  ###### */
@media (max-width: 991.98px) {
  .main-sidebar-show {
    overflow: inherit;
  }

  .main-sidebar-show .main-sidebar {
    display: flex;
    transform: translateX(0);
    visibility: visible;
    transition: left 0.3s ease, width 0.3s ease;
  }

  .main-sidebar-show .main-navbar-backdrop {
    opacity: 1;
  }
}

@media (min-width: 992px) {
  .main-sidebar-hide .main-sidebar {
    display: none;
  }

  .main-sidebar-hide .main-sidebar-sticky+.main-content {
    margin-left: 0;
  }
}

.main-body-sidebar {
  display: block;
}

.main-body-sidebar .main-content {
  flex: 1 1;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.main-body-sidebar .main-content-header {
  height: auto;
  padding: 20px;
  margin-bottom: 0;
}

.main-body-sidebar .main-content-body {
  padding: 0 20px 20px;
}

.main-body-sidebar .main-footer {
  margin-top: auto;
}

@media (min-width: 992px) {
  .main-body-sidebar {
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  .main-body-sidebar .main-header .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.main-sidebar {
  width: 240px;
  flex-direction: column;
  flex-shrink: 0;
  border-right: 0;
  transform: translateX(-240px);
  visibility: hidden;
  transition: all 0.4s;
  transition: left 0.4s ease, width 0.4s ease;
}

@media (prefers-reduced-motion: reduce) {
  .main-sidebar {
    transition: none;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .main-sidebar {
    width: 220px;
  }
}

@media (max-width: 991.98px) {
  .main-sidebar {
    position: fixed;
    top: 0px !important;
    left: 0;
    bottom: 0;
    z-index: 2;
    background-color: #ffffff;
  }
}

@media (min-width: 992px) {
  .main-sidebar {
    position: relative;
    display: flex;
    transform: none;
    z-index: 999;
    border-right-width: 1px;
    visibility: visible;
  }
}

.main-sidebar-sticky {
  position: fixed;
  top: 0px;
  left: 0;
  bottom: 0;
  box-shadow: none;
  background: #25233c;
}

.main-sidebar-body .nav-item.active .nav-link:before {
  content: "";
  position: absolute;
  top: -30px;
  z-index: 9;
  right: 0px;
  bottom: 0;
  height: 30px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom: transparent;
  border-radius: 0px 0px 48px 0px;
}

.main-sidebar-body .nav-item.active .nav-link:after {
  content: "";
  position: absolute;
  top: 43px;
  z-index: 0;
  right: 0px;
  bottom: 0;
  height: 30px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom: transparent;
  border-radius: 0px 48px 0px 0px;
}

.main-sidebar-body .nav-item.active .nav-link .shape1 {
  position: absolute;
  top: -30px;
  width: 19px;
  height: 60px;
  right: 0px;
}

.main-sidebar-body .nav-item.active .nav-link .shape2 {
  position: absolute;
  top: 35px;
  width: 19px;
  height: 30px;
  right: 0px;
}

.option-dots {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: transparent;
  margin: 0 auto;
  text-align: center;
  line-height: 40px;
  display: block;
}

.main-sidebar-body li.nav-item.active .nav-link {
  background-color: #eaedf7;
  position: relative;
  z-index: 1;
  text-align: center;
  border-radius: 30px 0 0 30px;
  margin-left: 0;
  border: 0px solid transparent;
  border-right: 0px;
  width: inherit;
}

.main-sidebar-body .nav-item.active .nav-link:before,
.main-sidebar-body .nav-item.active .nav-link:after {
  border-right: 20px solid #25233c;
}

.main-sidebar-body .nav-item.active .nav-link .shape1,
.main-sidebar-body .nav-item.active .nav-link .shape2 {
  background: #eaedf7;
}

@media (min-width: 992px) {
  .main-sidebar-sticky+.main-content {
    margin-left: 220px;
  }
}

@media (min-width: 1200px) {
  .main-sidebar-sticky+.main-content {
    margin-left: 240px;
  }
}

.main-sidebar-header {
  flex-shrink: 0;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.main-sidebar-loggedin {
  padding: 10px 20px;
  display: flex;
  flex-shrink: 0;
}

.main-sidebar-loggedin .main-img-user {
  width: 36px;
  height: 36px;
}

.main-sidebar-loggedin .media-body {
  margin-left: 12px;
}

.main-sidebar-loggedin .media-body h6 {
  font-weight: 700;
  font-size: 14px;
  color: #8f9cc0;
  margin-bottom: 2px;
}

.main-sidebar-loggedin .media-body span {
  display: block;
  color: #a8afc7;
}

.main-sidebar-body {
  padding: 0;
}

@media (min-width: 1200px) {
  .main-sidebar-body {
    padding: 12px 0px 12px 0px;
  }
}

.main-body .main-sidebar-body .nav {
  flex-direction: column;
  padding: 0 0 0 15px;
  margin: 0 auto;
  justify-content: center;
  margin-top: 0;
}

.nav-header {
  margin: 0 0 0 15px;
  display: flex;
  position: relative;
}

.main-sidebar-body .nav-label {
  text-transform: uppercase;
  font-size: 11.49px;
  font-weight: 500;
  letter-spacing: .5px;
  color: rgba(255, 255, 255, 0.3);
  padding: 10px 0px 0 0;
}

.second-sidemenu .nav-label {
  text-transform: uppercase;
  font-size: 11.49px;
  font-weight: 500;
  letter-spacing: .5px;
  color: #77778c;
  padding: 10px 0px 0 0;
}

.second-sidemenu .nav-header li {
  list-style-type: none;
}

.main-sidebar-body .sub-txt {
  font-size: 10.9px;
  padding: 0 0px 0 0;
  color: #9b9ca3;
  margin-bottom: 0;
  position: absolute;
  margin-top: -1px;
}

.second-sidemenu .sub-txt {
  font-size: 10.9px;
  padding: 0 0px 0 0;
  color: #8d95b1;
  margin-bottom: 10px;
}

.main-sidebar-body .nav-item {
  position: relative;
  display: block;
}

.main-sidebar-body .nav-item+.nav-item {
  margin-top: -1px;
}

.main-sidebar-body .nav-link {
  display: flex;
  align-items: center;
  padding: 4px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
}

.main-sidebar-body .nav-link .sidemenu-icon {
  font-size: 17px;
  line-height: 0;
  margin-right: 10px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
}

.main-sidebar-body .nav-link i.typcn {
  line-height: .9;
}

.main-sidebar-body .nav-link i.typcn::before {
  width: auto;
}

.main-sidebar-body .nav-link.with-sub {
  position: relative;
}

.main-sidebar-body .nav-sub {
  list-style: none;
  padding: 0;
  margin-left: 1.2rem;
}

.main-sidebar-body .nav-sub .nav-sub-link:before {
  content: '\e048';
  font-family: 'typicons';
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  top: 8px;
  font-size: 12px;
  opacity: 0.4;
  margin-right: 22px;
  color: rgba(255, 255, 255, 0.8);
}

.main-sidebar-body .nav-sub-item .nav-sub {
  padding: 0 0 0 12px;
  border-left-width: 1px;
  margin-left: 0;
}

.main-sidebar-body .nav-sub-item .nav-sub .nav-sub-link {
  height: auto;
  padding: 7px 0;
}

.main-sidebar-body .nav-sub-item.active>.nav-sub-link {
  color: #c6c6e0;
}

.main-sidebar-body .nav-sub-item.show .nav-sub {
  display: block;
}

.main-sidebar-body .nav-sub-link {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.4);
  font-size: 13px;
  padding: 8px 0;
}

.main-sidebar-body .nav-sub-link.with-sub {
  justify-content: flex-start;
}

.main-sidebar-body .nav-sub-link.with-sub::after {
  content: '\f3d0';
  font-family: 'Ionicons';
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  margin-left: auto;
  color: #f0f0ff;
}

.main-sidebar-body .nav-sub-link+.nav-sub-link {
  border-top: 1px dotted #f0f0ff;
}

.main-sidebar-body li.active .sidemenu-icon {
  font-size: 18px;
  line-height: 0;
  margin-right: 10px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  color: #ffffff;
  position: relative;

}

.main-sidebar-body li .sidemenu-icon svg {
  width: 20px;
  fill: #fff6;
}

.main-sidebar-body li.active .sidemenu-icon svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  fill: #fff;
}

.main-sidebar-body li.active .sidemenu-icon {
  background: #e35a88;
}

.main-sidebar-body li.active .nav-link,
a:hover {
  color: #e35a88;
}

a {
  color: #e35a88;
}

@media (min-width: 992px) {
  .main-sidebar-body .nav-sub-link.with-sub::after {
    margin-left: 4px;
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .main-sidebar-hide .main-sidebar-body li.active:after {
    top: 43px;
  }

  .main-sidebar-hide .main-sidebar .main-sidebar-body {
    padding: 17px 0px 12px 0px;
  }

  .main-sidebar-hide .main-sidebar-body .nav-link .sidemenu-icon {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
    z-index: 1;
    box-shadow: none;
  }

  .main-sidebar-hide.main-sidebar-open .main-sidebar-body li.active {
    position: relative;
    z-index: 1;
    margin: 0 auto;
    text-align: center;
    border-radius: 30px 0 0 30px;
    margin-left: 0;
    border: 0px solid transparent;
    border-right: 0px;
    transition: none;
  }

  .main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-link .sidemenu-icon {
    font-size: 18px;
    line-height: 0;
    margin-right: 10px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
    box-shadow: none;
  }

  .main-sidebar-hide.main-sidebar-open .main-sidebar-body li.active .sidemenu-icon {
    font-size: 18px;
    line-height: 0;
    margin-right: 10px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: #6259ca;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    color: #ffffff;
  }

  .main-body.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav {
    flex-direction: column;
    padding: 0 0 0 15px;
    text-align: inherit;
    margin: 0;
    justify-content: inherit;
  }

  .main-sidebar-hide .main-sidebar-body li.active {
    margin: 6px 0;
  }

  .main-sidebar-hide .main-sidebar-body li.active .sidemenu-icon {
    background: #6259ca;
    margin-left: 5px;
  }

  .main-sidebar-hide .main-sidebar-body li.nav-item.active .nav-link {
    width: inherit;
    transition: margin-left 0.3s ease;
  }

  .main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.active .nav-link:before {
    content: "";
    position: absolute;
    top: -30px;
    z-index: 9;
    right: 0px;
    bottom: 0;
    height: 30px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom: transparent;
    border-radius: 0px 0px 48px 0px;
  }

  .main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.active .nav-link:after {
    content: "";
    position: absolute;
    top: 43px;
    z-index: 0;
    right: 0px;
    bottom: 0;
    height: 30px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom: transparent;
    border-radius: 0px 48px 0px 0px;
  }
}

/* ###### Sidebar  ###### */


@media (min-width: 992px) {
  .side-header .main-logo {
    color: inherit;
    margin-right: 0;
    font-size: 1.25rem;
    white-space: nowrap;
    font-weight: 600;
    padding: 0;
    transition: .3s opacity;
    line-height: 2.9rem;
    text-align: center;
    text-align: center;
  }

  .main-sidebar-hide .side-header,
  .main-sidebar-hide .main-footer {
    padding-left: 70px;
  }

  .main-body .side-header {
    position: fixed;
    width: 100%;
    z-index: 10 !important;
    border-bottom: 1px solid #e1e6f1;
    box-shadow: -7.829px 11.607px 20px 0px rgba(144, 143, 160, 0.09);
    left: 0;
    padding-left: 240px;
    background-color: #fff;
    top: 0;
  }

  .main-body.main-sidebar-hide .side-header {
    padding-left: 75px;
  }

  .main-sidebar-hide .sidemenu-logo {
    padding: 8px 6px 6px 6px;
    border-bottom: 1px solid rgba(240, 240, 255, 0.1);
    justify-content: center;
    height: 65px;
  }

  .main-sidebar-hide.main-sidebar-open .sidemenu-logo {
    padding: 13.4px;
    border-bottom: 1px solid rgba(240, 240, 255, 0.1);
    justify-content: center;
  }

  .main-sidebar.side-menu {
    width: 240px;
  }

  .main-footer {
    margin-left: 0;
  }
}

@media (max-width: 992px) and (min-width: 767px) {
  .main-header-menu-icon {
    margin-left: 15px;
  }
}

@media (max-width: 767px) {
  .main-header-menu-icon {
    margin-left: 15px;
  }

  .side-header .icon-logo {
    margin-left: 0;
  }

  .main-sidebar-show .side-menu {
    top: 60px !important;
  }
}

@media (min-width: 768px) {

  /*main-sidebar-hide*/
  .main-body.main-sidebar-hide.main-sidebar-open .side-menu .nav-link {
    padding: 4px 0px !important;
  }

  .main-sidebar-hide .side-menu {
    left: 0;
    width: 80px;
    display: block;
    visibility: visible;
    transform: none;
  }

  .main-sidebar-hide .nav-label,
  .main-sidebar-hide .main-sidebar-body .sub-txt {
    display: none;
  }

  .main-sidebar-body .nav-label-1 {
    height: 28px;
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .5px;
    color: #ffffff;
    padding: 0;
    background: #07a7bb;
    width: 28px;
    border-radius: 50%;
    text-align: center;
    margin: 3px 11px;
    line-height: 2.1;
  }

  .main-sidebar-hide .nav-header {
    margin: 0;
  }

  .main-sidebar-hide.main-sidebar-open .nav-header {
    margin: 0 0 0 15px;
  }

  .main-sidebar-hide .sidemenu-label {
    display: none;
    font-size: 10px;
  }

  .main-sidebar-hide .nav-item {
    width: 100%;
  }

  .main-sidebar-hide .text-icon {
    display: none;
  }

  .main-sidebar-hide .iconbtn {
    display: block;
  }

  .main-sidebar-hide .main-sidebar-body i.angle {
    display: none;
  }

  .main-sidebar-hide .side-menu .nav-link {
    margin: 0 auto;
    padding: 4px 0;
    text-align: center;
    margin-bottom: 0;
  }

  .main-sidebar-hide .main-sidebar-body .nav-item {
    margin-top: -1px;
    text-align: center;
    margin: 6px 5px;
  }

  .main-sidebar-hide .main-sidebar-body .nav-item+.nav-item {
    margin-top: -1px;
    text-align: center;
  }

  .main-sidebar-hide .side-menu .main-logo .desktop-logo {
    display: none;
  }

  .main-sidebar-hide .side-menu .main-logo .icon-logo {
    display: block;
    margin-left: 10px;
  }

  .main-sidebar-hide .side-menu .header-brand-img {
    margin-top: 0 !important;
  }

  .main-sidebar-hide .main-sidebar-body .nav-item.show>.nav-sub {
    display: none;
  }

  .main-sidebar-hide .main-footer {
    margin-left: 0;
  }

  .main-sidebar-hide .side-menu .main-sidebar-body .nav-link i {
    font-size: 20px;
  }

  .main-sidebar-hide .side-menu i.angle {
    font-size: 12px !important;
  }

  /*main-sidebar-open*/
  .main-sidebar-open .side-menu {
    left: 0;
    width: 240px;
  }

  .main-sidebar-open .nav-label {
    display: block;
  }

  .main-sidebar-open .main-sidebar-body .sub-txt {
    display: block;
    font-size: 10.9px;
    padding: 0 0px 0 0;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 0;
  }

  .main-sidebar-open .main-sidebar-body .nav-label {
    text-transform: uppercase;
    font-size: 11.49px;
    font-weight: 500;
    letter-spacing: .5px;
    color: rgba(255, 255, 255, 0.3);
    padding: 10px 0px 0 0;
  }

  .main-sidebar-open .sidemenu-label,
  .main-sidebar-open .text-icon {
    display: block;
  }

  .main-sidebar-open .iconbtn {
    display: none;
  }

  .main-sidebar-open .main-sidebar-body i.angle {
    display: block;
  }

  .main-sidebar-open .side-menu .nav-link {
    display: flex;
    margin-bottom: 0px;
  }

  .main-sidebar-open .main-sidebar-body .side-menu .nav-link {
    margin-left: 10px;
  }

  .main-sidebar-open .sidemenu-label {
    font-size: 14px;
  }

  .main-sidebar-open .side-menu .main-logo .desktop-logo {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .main-sidebar-open .side-menu .main-logo .icon-logo {
    display: none;
  }

  .main-sidebar-open .main-sidebar-body .nav-item.show>.nav-sub {
    display: block;
  }

  .main-sidebar-open .side-menu .nav-item.show i.angle {
    transform: rotate(90deg);
    position: absolute;
    top: 16px;
    right: 20px;
  }

  .main-sidebar-open .side-menu .main-sidebar-body .nav-link i {
    margin-right: 5px;
    font-size: 18px;
  }

  .main-sidebar-open .side-menu i.angle {
    font-size: 12px;
  }
}

.main-header .select2-container .select2-selection--single .select2-selection__rEndered {
  display: block;
  padding-left: 17px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.main-sidebar .nav-sub-item.active .nav-sub-link {
  color: #fff !important;
}

@media (min-width: 768px) {
  .color-leftmenu.main-sidebar-hide .side-menu .main-logo .desktop-logo {
    display: none !important;
  }

  .color-leftmenu.main-sidebar-hide .side-menu .main-logo .icon-logo {
    display: block !important;
    margin-left: 10px;
  }

  .leftmenu.color-leftmenu.main-sidebar-hide .header-brand-img.icon-logo.theme-logo {
    display: none !important;
  }

  .color-leftmenu.main-sidebar-hide .header-brand-img.desktop-logo.theme-logo,
  .color-leftmenu.main-sidebar-hide .header-brand-img icon-logo theme-logo {
    display: none !important;
  }

  .color-leftmenu.leftmenu.main-sidebar-hide.main-sidebar-open .header-brand-img.icon-logo {
    display: none !important;
  }

  .color-leftmenu.leftmenu.main-sidebar-hide.main-sidebar-open .header-brand-img.icon-logo.theme-logo {
    display: block !important;
    display: none !important;
  }

  .color-leftmenu.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .desktop-logo {
    display: block !important;
  }

  .color-leftmenu.main-sidebar-hide.main-sidebar-open .main-logo .header-brand-img.desktop-logo.theme-logo {
    display: none !important;
  }

  .light-leftmenu.leftmenu .header-brand-img.desktop-logo {
    display: none !important;
  }

  .light-leftmenu.leftmenu .header-brand-img.desktop-logo.theme-logo {
    display: block !important;
  }

  .leftmenu.light-leftmenu.main-sidebar-hide .header-brand-img.icon-logo {
    display: none !important;
  }

  .leftmenu.light-leftmenu.main-sidebar-hide .header-brand-img.desktop-logo {
    display: none !important;
  }

  .leftmenu.light-leftmenu.main-sidebar-hide .header-brand-img.desktop-logo.theme-logo {
    display: none !important;
  }

  .leftmenu.light-leftmenu.main-sidebar-hide .header-brand-img.icon-logo.theme-logo {
    display: block !important;
    margin-left: 10px;
    margin-right: 0;
  }

  .leftmenu.light-leftmenu.main-sidebar-hide.dark-theme .header-brand-img.icon-logo.theme-logo {
    display: block !important;
  }

  .leftmenu.light-leftmenu.main-sidebar-hide.dark-theme .header-brand-img.desktop-logo.theme-logo {
    display: none !important;
  }

  .light-leftmenu.leftmenu.main-sidebar-open .header-brand-img.desktop-logo.theme-logo {
    display: block !important;
  }

  .leftmenu.light-leftmenu.main-sidebar-hide.main-sidebar-open .header-brand-img.icon-logo.theme-logo {
    display: none !important;
  }

  .leftmenu.light-leftmenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-label {
    color: #c8c4db;
  }

  .leftmenu.light-leftmenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .sub-txt {
    color: #a2a2b9;
  }

  .leftmenu.light-leftmenu.main-sidebar-hide .sidemenu-logo {
    border-bottom: 1px solid #f0f0ff;
  }

  .main-body.leftmenu .sidemenu-logo {
    position: fixed;
    z-index: 9999;
    width: 240px;
    background: #25233c;
    transition: all 0.4s;
  }

  .main-body.leftmenu .main-sidebar-body {
    margin-top: 65px;
  }

  .main-sidebar-hide .sidemenu-logo {
    position: fixed;
    z-index: 999;
    width: 80px !important;
    background: #25233c;
  }

  .main-sidebar-hide.main-sidebar-open .sidemenu-logo {
    width: 240px !important;
  }

  .main-sidebar-hide .main-sidebar-body {
    margin-top: 65px;
  }

  .dark-theme.main-body.leftmenu .sidemenu-logo {
    background: #0e0e23;
    border-right: 1px solid #2b2b3e;
  }

  .color-leftmenu.main-body.leftmenu .sidemenu-logo {
    background: #6259ca;
  }

  .dark-theme.light-leftmenu .sidemenu-logo {
    background: #fff;
  }

  .dark-theme.light-theme.main-body.leftmenu .sidemenu-logo {
    background: #0e0e23;
  }

  .dark-theme.color-leftmenu.main-body.leftmenu .sidemenu-logo {
    background: #6259ca;
  }

  .dark-theme.light-leftmenu.main-body.leftmenu .sidemenu-logo {
    background: #fff;
  }
}

*::-ms-backdrop,
.side-header {
  position: relative !important;
}

*::-ms-backdrop,
.main-sidebar-body .nav-sub .nav-sub-link:before {
  top: 0;
}

.side-menu .nav-sub-item.show>.nav-sub {
  max-height: 100vh;
}

.side-menu .nav-sub-item>.nav-sub {
  max-height: 0;
}

.main-sidebar-body .nav-sub-link.with-sub {
  position: relative;
  line-height: 2;
}

.main-sidebar-body .nav-sub-item .nav-sub-link.with-sub::after {
  display: none;
}

.side-menu .nav-item.show .nav-sub .nav-sub-link.with-sub i.angle {
  transform: rotate(0deg);
}

.side-menu .nav-item.show .nav-sub .nav-sub-item.show .nav-sub-link.with-sub i.angle {
  transform: rotate(90deg);
}

.side-menu .nav-item.show .nav-sub .nav-sub-item.show .nav-sub .nav-sub-link.with-sub i.angle {
  transform: rotate(0deg);
}

.side-menu .nav-item.show .nav-sub .nav-sub-item.show .nav-sub .nav-sub-item.show .nav-sub-link.with-sub i.angle {
  transform: rotate(90deg);
}

/*# sourceMappingURL=sidemenu.css.map */
 :root {
   --primary-bg-color: #0068ff;
   --primary-transparentcolor: #e4e9ff;
   --primary-bg-color: #0068ff;
   --indigo: #4b0082;
   --purple: #6f42c1;
   --pink: #f1388b;
   --orange: #fd7e14;
   --yellow: #ffc107;
   --green: #28a745;
   --teal: #20c997;
   --cyan: #17a2b8;
   --white: #fff;
   --gray: #6c757d;
   --gray-dark: #343a40;
   --primary: #007bff;
   --secondary: #6c757d;
   --success: #28a745;
   --info: #17a2b8;
   --warning: #ffc107;
   --danger: #dc3545;
   --light: #f8f9fa;
   --dark: #343a40;
   --breakpoint-xs: 0;
   --breakpoint-sm: 576px;
   --breakpoint-md: 768px;
   --breakpoint-lg: 992px;
   --breakpoint-xl: 1200px;
   --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
   --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
 }

 * {
   box-sizing: border-box;
 }

 *::before,
 *::after {
   box-sizing: border-box;
 }

 html {
   font-family: sans-serif;
   line-height: 1.15;
   -webkit-text-size-adjust: 100%;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 }

 article,
 aside,
 figcaption,
 figure,
 footer,
 header,
 hgroup,
 main,
 nav,
 section {
   display: block;
 }

 body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
   font-size: 0.875rem;
   font-weight: 400;
   line-height: 1.5;
   text-align: left;
   background-color: #ffffff;
   font-family: "Roboto", sans-serif !important;
   background: #f0f0ff;
   color: #1d212f;
   direction: ltr;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   -webkit-tap-highlight-color: transparent;
   -webkit-text-size-adjust: none;
   touch-action: manipulation;
   font-feature-settings: "liga" 0;
   overflow-y: scroll;
   width: 100%;
   position: relative;
 }

 [tabindex="-1"]:focus {
   outline: 0 !important;
 }

 hr {
   box-sizing: content-box;
   height: 0;
   overflow: visible;
   margin-top: 1rem;
   margin-bottom: 1rem;
   border: 0;
   border-top: 1px solid #e8e8f7;
 }

 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
   margin-top: 0;
   margin-bottom: 0.5rem;
 }

 p {
   margin-top: 0;
   margin-bottom: 1rem;
 }

 body {
   font-size: 0.875rem;
   font-weight: 400;
   line-height: 1.5;
   text-align: left;
   font-family: "Roboto", sans-serif !important;
   color: #1d212f;
   direction: ltr;
   -webkit-text-size-adjust: none;
   font-feature-settings: "liga";
   background: #eaedf7;
 }

 body.main-body.leftmenu {
   background: #eaedf7;
 }

 .page-header {
   display: flex;
   align-items: center;
   margin: 1.5rem 0 1.5rem;
   -ms-flex-wrap: wrap;
   justify-content: space-between;
   padding: 0;
   position: relative;
   min-height: 50px;
   margin: 1.8rem 0 0.75rem 0;
   border-radius: 7px;
 }

 a {
   color: #424e79;
 }

 .accordion .card-header {
   padding: 0px !important;
 }

 .accordion .card-header a {
   display: block;
   padding: 14px 20px;
   position: relative;
   font-weight: 500;
   font-size: 14px;
   background-color: #e1e1f9;
 }


 .btn.btn-white:hover svg {
   fill: #fff;
 }

 .fontAwesome-size-medium {
   max-width: 15px;
   margin-right: 4px;
   margin-top: -2px;
 }

 .main-content-title {
   color: #170c6b;
   font-weight: 500;
   font-size: 32px;
   text-indent: -1px;
   line-height: 1;
   position: relative;
 }

 .tx-24 {
   font-size: 24px;
 }

 .mg-b-5 {
   margin-bottom: 5px;
 }

 .page-header .breadcrumb {
   background: none;
   padding-left: 0 !important;
   padding: 0;
   margin-bottom: 0;
 }

 .breadcrumb {
   display: flex;
   flex-wrap: wrap;
   padding: 0.75rem 1rem;
   margin-bottom: 1rem;
   list-style: none;
   border-radius: 0px;
 }

 .card {
   border-radius: 11px;
   position: relative;
   display: flex;
   flex-direction: column;
   min-width: 0;
   word-wrap: break-word;
   background-color: #fff;
   background-clip: border-box;
 }

 .btn,
 .sp-container button {
   line-height: 1.538;
   padding: 7px 20px;
   border-radius: 4px;
   transition: none;
   min-height: 38px;
   display: inline-block;
   font-weight: 400;
   color: #8f9cc0;
   text-align: center;
   vertical-align: middle;
   -webkit-user-select: none;
           user-select: none;
   background-color: transparent;
   border: 1px solid transparent;
   padding: 0.375rem 0.75rem;
   font-size: 0.875rem;
   line-height: 1.5;
   border-radius: 3px;
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
 }

 .btn-white:hover {
   background-color: #424e79;
   border-color: #424e79;
   background-image: none;
   border-color: #fff;
   color: #fff;
 }

 .btn-white {
   background-color: #fff;
   background-image: none;
   border-color: #fff;
   color: #424e79;
 }

 .card-item-icon.card-icon svg {
   max-width: 24px;
 }

 .fontAwesome-size {
   max-width: 20px;
 }

 .fontAwesome-size-small {
   max-width: 10px;
 }

 .text-success svg {
   fill: #28a745 !important;
 }

 .text-danger svg {
   fill: #dc3545 !important;
 }

 .card-item .card-item-body .card-item-stat small {
   font-size: 85%;
   font-weight: 300;
   text-transform: capitalize;
   color: #8d95b1;
 }

 .font-weight-bold {
   font-weight: 700 !important;
 }

 .custom-card {
   margin-bottom: 20px;
   border: 0;
   box-shadow: -7.829px 11.607px 20px 0px #e4e3f5;
 }

 .main-content .container,
 .main-content .container-fluid {
   padding-left: 25px;
   padding-right: 25px;
 }

 .main-header>.container,
 .main-header>.container-fluid {
   padding-left: 25px;
   padding-right: 15px;
 }

 .card-item .card-item-icon.card-icon {
   background: #4d4cb21a;
 }

 .card-item .card-item-icon {
   width: 45px;
   height: 45px;
   position: absolute;
   right: 20px;
   bottom: 21px;
   top: auto;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
 }

 .main-content-label,
 .card-table-two .card-title,
 .card-dashboard-eight .card-title {
   color: #1d212f;
   font-weight: 800;
   text-transform: uppercase;
   line-height: 1;
   margin-bottom: 10px;
   letter-spacing: .2px;
 }

 @media only screen and (min-width:992px) {
   .main-header-left {
     display: none;
   }

   .main-header-center {
     display: none;
   }

   .main-header-right {
     width: 100%;
   }
 }

 @media only screen and (max-width:992px) {
   .main-header-left {
     display: flex;
   }

   .main-header-center {
     display: flex;
   }

   .main-header-right {
     display: none;
   }

   .mobile-logo {
     max-width: 100px;
     position: relative;
     top: -4px;
   }
 }

 .row {
   min-width: 100%;
 }

 .position-fixed-footer {
   position: fixed;
   left: 0px;
   bottom: 0px;
   right: 0px;
 }

 .text-with-logo {
   float: left;
   width: 100%;
 }

 .side-header {
   position: fixed !important;
 }

 .blackToast .Toastify__toast-body {
   line-height: 24px;
 }

 .blackToast {
   background-color: #000;
   line-height: 1 !important;
 }

 .blackToast .Toastify__progress-bar {
   background-color: #e35a88;
 }

 .message-watch {
   background: #eefaec;
   padding: 10px;
   width: 100%;
   border-left: 4px solid #008000;
 }

 .text-with-logo * {
   display: inline-block;
   float: none !important;
   vertical-align: middle;
   margin-bottom: 0px !important;
   padding-right: 5px;
 }

 .header-right ul {
   margin-bottom: 0px;
   text-align: right;
   display: block;
 }

 .header-right ul li {
   list-style: none;
   text-align: right;
   display: inline-block;
   line-height: 50px;
 }

 .main-header.side-header {
   line-height: 50px;
 }

 .header-right ul li a {
   padding: 0px 15px !important;
 }

 .main-body .main-header {
   height: 60px;
 }

 .main-header.side-header,
 .main-header.side-header ul li {
   line-height: 50px;
   font-size: 13px;
   color: #4a4d51;
 }

 .main-header.side-header ul li a {
   color: #4a4d51;
 }

 .main-header.side-header ul li a i {
   margin-right: 5px;
 }

 .main-header.side-header ul li a .main-header.side-header ul li a:hover {
   color: #e35a88
 }

 .main-sidebar-body li.active .sidemenu-icon {
   font-size: 18px;
   line-height: 0;
   margin-right: 10px;
   width: 35px;
   height: 35px;
   line-height: 35px;
   text-align: center;
   border-radius: 50%;
   box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
   color: #ffffff;
 }

 .main-sidebar-body li.active .sidemenu-icon {
   background: #e35a88;
 }

 .main-sidebar-body li.active a .sidemenu-label {
   color: #e35a88;
 }

 .btn.btn-white.btn-icon-text i {
   padding-right: 5px;
 }

 .card-item-stat-abs b {
   font-weight: bold;
   display: block;
   line-height: 12px;
 }

 .card-item-stat-abs {
   position: absolute;
   bottom: 15px;
 }

 .card-item-stat.card-item-stat-rel {
   position: relative;
   padding-bottom: 40px;
 }

 .heat-icon img {
   max-width: 50px;
   margin-bottom: 10px;
   padding: 10px;
   background-color: #f2f2f2;
   border-radius: 50%;
   opacity: .8;
 }

 .card.custom-card {
   min-height: 160px;
 }

 .card-item-stat.card-item-stat-table td:first-child {
   font-weight: 600;
 }

 .col-md-8.stats-area-left {
   padding-right: 0px;
 }

 .card-item-stat h4 {
   font-size: 14px;
 }

 .card-item-stat.card-item-stat-table {
   width: 100%;
   padding-bottom: 40px;
 }

 .card-item-stat-table-less .table {
   margin-bottom: 0px;
 }

 .custom-card .card-body {
   padding: 15px;
 }

 .card-item-stat.card-item-stat-table td {
   font-size: 12px;
   padding: 4px 0px;
   color: #8d95b1;
   border-bottom: 1px solid #f0f0ff;
   text-transform: capitalize;
 }

 .card-item-stat.card-item-stat-table tr:last-child td {
   border-bottom: 0px;
 }

 .card-item-stat.card-item-stat-table.card-item-stat-table-less {
   padding-bottom: 0px;
 }

 .accordion>.card:last-of-type .collapse.show .card-body {
   border-radius: 0px 0px 20px 20px !important;
   border-bottom: 1px solid #e8e8f7;
 }

 .btn-primary {
   color: #fff;
   background-color: #e35a88;
   border-color: #e35a88;
 }

 .btn-primary:hover {
   color: #fff;
   background-color: #e35a88;
   border-color: #e35a88;
 }

 #accordion .btn {
   padding: 10px 20px;
   line-height: 1;
   border-radius: 4px;
   border-radius: 4px;
   border: 0px;
 }

 #accordion .btn.cancel-btn {
   color: #fff;
   background-color: #656565;
 }

 .accordion>.card>.card-header a {
   cursor: pointer;
   display: block;
 }

 .accordion .card-header a.collapsed:hover,
 .accordion .card-header a.collapsed:focus {}

 .accordion .card-header a {
   display: block;
   padding: 14px 20px;
   position: relative;
   font-weight: 500;
   font-size: 14px;
   background-color: #e1e1f9 !important;
   color: #3c4858 !important;
 }

 .accordion .card-header a.collapsed {

   background-color: #e1e1f9 !important;

   color: #8f9cc0 !important;
   background-color: #f5f5fd !important;

 }

 #accordion .btn.cancel-btn {
   margin-right: 10px;
 }

 .accordion>.card>.card-header a svg {
   max-width: 13px;
   margin-right: 5px;
   fill: #3c4858 !important;
 }

 .accordion>.card>.card-header a svg.fill-inner * {
   fill: #3c4858 !important;
 }

 .accordion>.card>.card-header a.collapsed svg,
 .accordion>.card>.card-header a.collapsed svg.fill-inner * {
   fill: #8f9cc0 !important;
 }

 .accordion>.card>.card-header a.collapsed svg {
   fill: #8f9cc0 !important
 }

 .ant-picker-cell .ant-picker-cell-inner,
 .ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
   font-size: 12px;
   color: #4a4d51;
 }

 .ant-picker {
   height: calc(1.5em + .75rem + 2px);
   padding: .375rem .75rem;
 }

 .ant-space.ant-space-vertical {
   grid-gap: 0px !important;
   gap: 0px !important;
 }

 #gauge-chart4 .text-group {
   display: none;
 }

 iframe {
   z-index: -9 !important;
 }

 .pos-abs-right {
   position: absolute;
   right: 15px;
   top: 15px;
   font-size: 16px;
   line-height: 30px;
 }

 .breadcrumb li a {
   color: #7c858d;
 }

 .br-btm-field {
   padding-bottom: 10px;
   margin-bottom: 10px;
   border-bottom: 1px solid #e1e1f9;
 }

 .accordion .mt-3.text-right {
   background-color: #fbfbfb;
   padding: 10px;
   border: 1px solid #ddd;
 }

 .accordion .mt-3.text-right {
   background-color: #fbfbfb;
   padding: 10px;
   border-top: 1px solid #ddd;
   margin-left: -20px;
   margin-bottom: -20px;
   margin-right: -20px;
 }

 .subTabSafeAir {
   border-bottom: 1px solid #ddd;
   margin-bottom: 15px;
 }

 .subTabSafeAir .subtab {
   padding: 10px 20px;
   display: inline-block;
   border-bottom: 1px solid transparent;
   cursor: pointer;
 }

 .float-end {
   float: right;
 }

 .OnBoardAreaa {
   width: 100%;
   background: #fff;
   padding: 20px;
 }

 .btn-primary svg {
   max-width: 12px;
   position: relative;
   top: -1px;
   fill: #fff;
   margin-right: 4px;
 }

 .ag-theme-alpine .ag-header {
   background: #f5f5fd;

 }

 .element-icon .btn.btn-delete.btn-primary {
   max-width: 30px;
 }

 .btn.btn-delete.btn-primary img {
   max-width: 20px;
 }

 .subTabSafeAir .subtab:first-child {
   padding-left: 0px;
 }

 .subTabSafeAir .subtab.active {
   color: #e35a88;
   border-bottom: 1px solid #e35a88;
   margin-bottom: -1px;
 }

 .ant-select .ant-select-selector {
   display: block;
   width: 100%;
   height: calc(1.5em + .75rem + 2px) !important;
   padding: .375rem .75rem !important;
   font-size: 1rem !important;
   font-weight: 400;
   line-height: 1.5;
   color: #495057;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid #ced4da;
   border-radius: .25rem;
   transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
 }

 .ant-select-selection-placeholder {
   line-height: 25px !important;
   font-size: 1rem !important;
   color: #495057;
   opacity: .7;
 }

 .ant-radio-inner::after {
   color: #e35a88 !important;
 }

 .ant-radio-inner::after {
   background-color: #e35a88 !important;
 }

 .ant-radio-wrapper:hover .ant-radio,
 .ant-radio:hover .ant-radio-inner,
 .ant-radio-input:focus+.ant-radio-inner,
 .ant-radio-checked .ant-radio-inner {
   border-color: #e35a88 !important;
 }

 .ant-radio-checked .ant-radio-inner {
   border-color: #e35a88;
 }

 .ant-checkbox-checked .ant-checkbox-inner {
   background-color: #e35a88 !important;
   border-color: #e35a88 !important;
 }

 .ant-checkbox-checked::after {
   border: 1px solid #e35a88 !important;
 }

 .ant-select {
   width: 100%;
 }

 .headerTabMac {
   width: 100%;
 }

 .headerTabMac .tab-mac {
   font-size: 12px;
   padding: 5px 15px;
   color: #8d95b1;
   font-weight: bold;

   display: inline-block;
   border-bottom: 1px solid transparent;
   cursor: pointer;
 }

 .headerTabMac .tab-mac:first-child {
   padding-left: 0px;
 }

 .headerTabMac .tab-mac.active {
   color: #e35a88;
   border-color: #e35a88;
 }

 .element-icon .btn.btn-delete.btn-primary {
   max-width: 30px;
   width: 30px;
   height: 15px !important;
   display: inline-block;
   min-height: 30px;
   padding: 0px !important;
 }

 .logoutModal .row {
   margin: 0px;
 }

 .modalAddDevice .logoutTitle {
   font-size: 20px;
   font-weight: 500;
 }

 .modalAddDevice .btn-close.btn-tiny {
   position: absolute;

   right: 20px;
   top: 20px;
   width: 25px;
   height: 25px;
   background-color: #e35a88;
   text-align: center;
   color: #fff;
   cursor: pointer;
 }

 .modalAddDevice .br-btm-field {
   padding-bottom: 0px;
   margin-bottom: 20px;
 }

 .ReactModal__Content.ReactModal__Content--after-open.modalAddDevice {
   height: 320px !important;
   background-color: #f9fafa !important;
 }

 .modalAddDevice .btnText.okBtn.btn.btn-primary {
   width: 100%;
 }

 legend .main-content-label.mb-1 {
   font-size: 12px;
 }

 .br-top-field {
   padding-top: 15px;
   margin-top: 15px;
   border-top: 1px solid #ddd !important;
 }

 .ssid-scanner {
   position: relative;
 }

 .ssid-scanner .scan-submit {
   position: absolute;
   right: 1px;
   width: 35px;
   padding: 6px;
   background: #fcfcfc;
   top: 1px;
   border-radius: 4px;
   cursor: pointer;
   border: 1px solid #f4f4f4;
 }

 .card.custom-card.custom-card-stats-size-4 {
   min-height: 200px;
 }

 .card.custom-card.custom-card-stats.custom-card-stats-size-auto {
   min-height: 1px;
 }

 .custom-card-stats-size-auto table tr:first-child td::before {
   content: '';
   width: 6px;
   height: 6px;
   background-color: #8d95b1;
   position: relative;
   display: inline-block;
   border-radius: 50%;
   margin-right: 5px;
   top: -1px;
 }

 .card.custom-card.custom-card-stats.custom-card-stats-size-3 {
   min-height: 390px;
 }

 .custom-card-stats-size-auto table tr:first-child td {
   text-transform: uppercase;
   background: #e7e8f6;
 }

 .custom-card-stats-size-auto .card-item-stat-table-less .table td {
   padding: 5px 20px;
   border-right: 1px solid #ddd;
 }

 .custom-card-stats-size-auto .card-item-stat-table-less .table {
   border: 1px solid #ddd;
   border-radius: 12px !important;
 }

 .main-header.side-header ul li svg {
   max-width: 10px;
   margin-right: 5px;
   fill: #fff;
 }

 .main-header.side-header ul li .btn-shape {
   background: #e35a88;
   padding: 7px 20px;
   border-radius: 5px;
   color: #fff;
   width: 100px;
   display: inline-block;
   line-height: 1;
 }

 .rebootNote strong {
   color: #e35a88;
   font-weight: normal;
 }

 .help-form.help-form-error {
   color: #ab3131;
 }

 .main-header.side-header ul li .btn-shape:hover {
   background-color: #ed5e8e;
 }

 .main-header.side-header ul li a {
   display: inline-block;
 }

 .timercolor {
   font-weight: bold;
 }

 .ant-select-dropdown-empty {
   display: none !important;
 }

 .has-error.form-control {
   border: 1px solid red;
 }

 .loadingMain {
   display: none;
   text-align: center;
 }

 .loading .loadingMain {
   display: block;
 }

 .loading fieldset {
   display: none;
 }

 .has-error {
   border: 1px solid red;
 }

 .ant-switch-checked {
   background-color: #e35a88;
 }

 .btn[disabled],
 .btn-primary.disabled {
   background-color: #e35a88;
   opacity: .5;
 }

 .ant-switch-checked {
   background-color: #e35a88 !important;
 }

 .btn-primary.focus,
 .btn-primary:focus,
 .btn-primary:not(:disabled):not(.disabled):active {
   color: #fff;
   background-color: #e35a88;
   border-color: #e35a88;
   box-shadow: 0 0 0 .2rem #e35a8830 !important;
 }

 .help-form {
   color: #a29999;
 }

 .loaderspinner {
   border: 3px solid #f3f3f3;
   border-radius: 50%;
   border-top: 3px solid #a8a7b3;
   width: 25px;
   height: 25px;
   animation: spin 2s linear infinite;
   display: inline-block;
 }

 .sidemenu-logo.log-safeair img {
   max-width: 200px;
 }

 .sidemenu-logo.log-safeair {
   padding: 18px 15px;
 }

 .btn.invite-btn.btn-primary.btn-icon-text:hover {
   background-color: #656565;
 }

 .btn.cancel-btn.btn-white:hover {
   background-color: #e35a88;
 }

 /* Safari */

 @keyframes spin {
   0% {
     transform: rotate(0deg);
   }

   100% {
     transform: rotate(360deg);
   }
 }

 .toast-class-loading .Toastify__close-button.Toastify__close-button--default {
   display: none !important;
 }

 .Toastify__toast-body,
 .Toastify__toast-body * {
   line-height: 30px;
   display: inline-block;
 }

 .toast-class-loading .Toastify__toast-body span.loaderspinner {
   position: absolute;
   left: 10px;
 }

 .toast-class-loading .Toastify__toast.Toastify__toast--default {
   background: #fff;
   color: #aaa;
   padding-top: 0px;
   padding-bottom: 0px;
   height: 50px !important;
   overflow: hidden;
   min-height: 50px;
 }

 .toast-class-loading .Toastify__toast-body {
   padding-left: 40px;
 }

 .toast-class-loading .Toastify__toast-body,
 .toast-class-loading .Toastify__toast-body * {
   line-height: 25px;
   display: inline-block;
 }

 .toast-class-loading .Toastify__close-button {
   display: none !important;
 }

 .toast-class-loading .Toastify__progress-bar {
   display: none !important;
 }

 .ant-radio-group.has-error .ant-radio-inner {
   border-color: red;
 }

 .ant-radio-group.has-error {
   border: 0px;
 }

 .form-field.formfielSubmit .btn.btn-submit {
   margin-top: 25px;
   background: #ef5e8c;
   color: #fff;
   width: 100%;
 }

 .ag-cell-value {
   text-transform: capitalize;
 }
    @media only screen and (max-width: 1400px) {}

    /*Small Desktop */
    @media only screen and (max-width: 1200px) {}


    @media only screen and (max-width: 1023px) and (min-width:992px) {}


    @media only screen and (max-width:992px) {}


    @media only screen and (max-width:767px) {
      .topStatsArea .bgWhite {
        padding: 10px 0px 30px 0px;
      }

      .biggerCountArea {
        font-size: 60px;
      }

      .smallerTitleArea {
        font-size: 16px;
      }

      .onBoardScanArea .onBoardScanInner .form-field.form-field2 {
        width: 100%;
      }

      .onBoardScanArea .onBoardScanInner .form-field.form-field2 input {
        width: 70%;
      }

      .onBoardScanArea .onBoardScanInner .form-field p {
        width: 12%;
      }
    }

    @media only screen and (max-width:650px) {
      .biggerCountArea {
        font-size: 60px;
      }
    }
*,
body {
  font-family: 'Sen', sans-serif;
}

.onBoardScanArea .onBoardScanInner .form-field {
  width: 100%;
}

.OnBoardAreaa {
  width: 100%;
  background: #fff;
  padding: 20px;
  padding-top: 60px;
  margin-top: -60px;
}


.onBoardAreaInner {
  float: none;
  margin: 20px auto;
  width: 1000px;
}

.errormessage {
  background: #f7f8f9;
  padding: 2px 20px;
  border-left: 4px solid red;
  border-radius: 4px;
  margin-top: 6px;
  position: absolute;
  width: 100%
}

.sucessMessage {
  background: #f7f8f9;
  padding: 2px 20px;
  border-left: 4px solid green;
  border-radius: 4px;
  margin-top: 6px;
  position: absolute;
  width: 100%
}

.fieldBottom {
  margin: 12px 0px;
}

.fieldBottom .fieldList {
  margin-bottom: 2px;
  font-size: 12px;
}

.contentArea {
  margin-top: 30px;
  background: #fff;
  padding: 40px;
  z-index: 99;
  position: relative;
  border-radius: 8px;
}

.onBoardLeft {
  float: left;
}

.onBoardright {
  float: right;
}

.btn.btn-pink {
  min-width: 100px;
  background: #ef5e8c;
  color: #fff;
}

.breadcrumb-item.active strong {
  color: #e7457b;
}

.ant-modal {
  border-radius: 10px !important;
  overflow: hidden;
}

.ant-btn.ant-btn-primary:hover {
  background: #cc2359;
  border-color: #ef5e8c;
  min-width: 100px;
}

.ant-btn.ant-btn-default:hover {
  color: #e7457b;
  border-color: #ef5e8c;

}

.tableArea {
  width: 100%;
}

.textCapitalize.textCap.ag-cell-value {
  text-transform: capitalize;
}

.topArea {
  float: left;
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.ag-theme-alpine.if.cell-size-40 {
  overflow: hidden;
}



.tableContentArea.row .errorMessage {
  margin-top: 15px;
}


.rightDivHeader div {
  display: inline-block;
}

.rightDivHeader {
  min-width: 200px;
}

.rightDivHeader .hourStyle {
  padding: 9px 20px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 500;
}

.rightDivHeader .btnLogout .btn {
  margin-left: 10px;
  font-size: 13px;
  border-radius: 4px;
  color: #fff;
  width: 40px;
  border: 0px;
  background: #fff;
  padding: 0px;
}

.rightDivHeader .btnLogout .btn img {
  max-width: 15px;
}

.ant-btn.ant-btn-primary {
  background: #ef5e8c;
  border-color: #ef5e8c;
  min-width: 100px;
}

.logoArea img {
  max-width: 100%;
  height: auto;
  width: auto;
}

.datepickerStyle .react-datepicker-wrapper,
.datepickerStyle .react-datepicker-wrapper input {
  max-width: 100px !important;
}

label .datepickerStyle .react-datepicker-wrapper input {
  border: 1px solid #ddd;
  padding: 5px;
  margin: 0px 10px;
}

.datepickerStyle {
  margin-right: 30px;
}

.datepickerStyle .labelDiv,
.datepickerStyle input {
  display: inline-block;
}

.datepickerStyle .react-datepicker-wrapper input {
  margin: 0px 10px;
  padding: 5px 20px;
  font-size: 12px;
  border: 1px solid #ddd;
}

.datepickerStyle {
  display: inline-block;
}

.topStatsArea {
  margin-bottom: 20px;
}

.topStatsArea .bgWhite {
  padding: 10px 60px 30px 60px;
  background: #fff;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0px 2px 8px #e2ecf980;
  overflow: auto;
}

.biggerCountArea {
  font-size: 72px;
  text-align: center;
  color: #170c6b;
}

.smallerTitleArea {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  color: #929699;
}

.statsLeftRight {
  font-size: 13px;
  color: #414c51;
  margin-top: 20px;
}

.smallerSubTitleArea {
  text-align: center;
}

.onBoardScanArea .onBoardScanInner {
  width: 100%;
  float: none;
  margin: 0 auto;

  border-radius: 8px;
  z-index: 99;
  position: relative;
  overflow: hidden;
}

.topAreaScan.row {
  padding: 20px;
  margin: 0px;
  background: #263053;
}

.ScanFieldFilter.row {
  margin: 0px;
  padding: 20px;
  background: #fff;
  margin-top: 15px;
}

.topAreaScan .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #fcfdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #d9d9d940;
}

.topAreaScan.row .fieldBottom .fieldList {
  font-size: 14px;
  margin-bottom: 7px;
}

.topAreaScan.row .col-lg-4:last-child .innerDiv {
  height: 120px;
}

.topAreaScan.row .innerDiv {
  padding: 20px;
  border: 2px solid #ddd;
  height: 100%;
  background: #e9ecf6;
  border-radius: 8px;
}

.form-field.formfielSubmit .btn.btn-submit {
  margin-top: 14px;
  background: #ef5e8c;
  color: #fff;
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
}

.onBoardScanArea .onBoardScanInner .form-field {
  width: 100%;
}

.onBoardScanArea .onBoardScanInner .form-field p {
  width: 40px;
  float: left;
  margin-top: 0px !important;
  background: #fff;
  line-height: 35px;
  padding: 0px;
}

.topAreaScan.row .col-lg-6 label {
  color: #fff;
}

.onBoardScanArea .onBoardScanInner .form-field p svg {
  max-width: 16px;
}

.ant-select.ant-select-single.ant-select-show-arrow.ant-select-disabled {
  opacity: .6;
}

.onBoardScanArea .onBoardScanInner .form-field.form-field2 {
  float: none;
  margin: 0 auto;
  width: 500px;
}

.onBoardScanArea .onBoardScanInner .form-field.form-field2 input {
  width: 400px;
  float: left;
  background: #eaedf7;
}

.Toastify__toast-container--top-right {
  width: auto !important;
  max-width: 400px;
}

.onBoardScanArea .onBoardScanInner .form-field.form-field2 input[disabled] {
  background: #837c7c;
  border-color: #837c7c;
  transition: ease-in .3s;
}

.onBoardScanArea .onBoardScanInner .form-field.form-field2 input[disabled]::placeholder {
  opacity: 1;
  font-size: 13px;
  color: #c4bebe;
}

.noteMessage {
  display: inline-block;
  background: #263053;
  color: #fff;
  padding: 4px 10px;
  font-size: 12px;
}

.ant-modal.logoutModal .ant-modal-header {
  text-transform: uppercase;
  font-weight: bolder;
}

.ant-modal.logoutModal {
  height: 245px;
}

.ant-modal.logoutModal .ant-modal-footer {
  text-align: center;
  height: 70px;
}

.ant-modal.logoutModal .ant-modal-body {
  padding: 15px 21px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.ant-modal.logoutModal .ant-modal-footer button {
  min-width: 47% !important;
  height: 45px;
  border-radius: 12px;
}

.ant-modal.logoutModal.logoutModalHieght2 {
  height: 450px;
  overflow: visible;
  border-radius: 10px !important;
  background: #ffebeb !important;
  padding: 4px;
}

.ant-modal.logoutModal.logoutModalHieght2 .warning-img {
  max-width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-bottom: 30px;
}

.ant-modal.logoutModal.logoutModalHieght2 .ant-modal-content {
  background-color: #ffebeb;
}

.ant-modal.logoutModal.logoutModalHieght2 .ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #ffebeb;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.ant-modal.logoutModal.logoutModalHieght2 .ant-modal-content {
  background-color: #ffebeb;
  box-shadow: unset !important;
}

.modalHeaderCustom {
  text-align: center;
}

.ant-modal.logoutModal.logoutModalHieght2 p strong {
  font-size: 16px;
}

.shiftShort {
  font-size: 24px;
  text-align: center;
  color: #c02020;
  margin-top: -20px;
}

.shiftShort small {
  display: block;
  font-size: 14px;
  line-height: 1;
  color: #000;
}

.smallTaglineResp {
  display: none;
}


.div100 {
  float: left;
  width: calc(100% + 120px);
  margin: 0px -60px;
  margin-top: 20px;
  margin-bottom: -30px;
}

.smallLanguage .ant-select-selector {
  padding: 0px 10px !important;
  min-height: 10px !important;
  height: 30px !important;
  border: 0px !important;
  border-radius: 4px !important;
  text-align: left;
  font-size: 13px !important;
}

.div100 .div50 {
  width: 50%;
  float: left;
  text-align: center;
  padding: 6px;
  font-size: 16px;
  background: #f7f7f7;
}

.div100 .div50:first-child {
  border-right: 1px solid #e0e0e0
}

.div100 .div50 span {
  display: block;
  font-weight: bold;
  font-size: 24px;
}

.div100 .div50:first-child {
  background: #f7f7f7;
}

.div100 .div50:first-child span {
  color: #047204;

}

.div100 .div50:last-child span {
  color: red;
}

/* ---- Responsive start ------ */
@media only screen and (max-width: 1400px) {}

/*Small Desktop */
@media only screen and (max-width: 1200px) {}


@media only screen and (max-width: 1023px) and (min-width:992px) {}


@media only screen and (max-width:992px) {}


@media only screen and (max-width:767px) {
  .topStatsArea .bgWhite {
    padding: 10px 0px 30px 0px;
  }

  .topStatsArea .div100 {
    width: 100%;
    margin: 20px 0px -10px 0px;
  }

  .div100 .div50 span {
    display: inline-block;
    font-size: 12px;
  }

  .biggerCountArea {
    font-size: 60px;
  }

  .smallerTitleArea {
    font-size: 16px;
  }

  .onBoardScanArea .onBoardScanInner .form-field.form-field2 {
    width: 100%;
  }

  .onBoardScanArea .onBoardScanInner .form-field.form-field2 input {
    width: 70%;
  }

  .onBoardScanArea .onBoardScanInner .form-field p {
    width: 12%;
  }

  .topAreaScan.row .col-12 {
    margin-bottom: 15px;
  }

  .topAreaScan.row {
    padding-bottom: 0px;
  }

  .hideResp {
    display: none;
  }

  .page-header div {
    padding: 0px;
  }

  .smallTaglineResp {
    display: block;
  }

  .topAreaScan.row div {
    padding-left: 0px;
    padding-right: 0px;
  }

  .main-content .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  .topStatsArea .row .col-6:first-child {
    padding-right: 0px;
  }

  .page-header {
    margin: 10px 0px;
    padding: 10px;
    background-color: #fff;
  }

  .datepickerStyle {
    width: calc(50% - 10px);
    float: left;
    margin-right: 10px;
  }

  .datepickerStyle .react-datepicker-wrapper,
  .datepickerStyle .react-datepicker-wrapper input {
    width: 100%;
    margin: 0px;
  }

  .noteMessage {
    display: none;
  }

  .contentArea {
    margin-top: 20px;
    padding: 12px;
  }

  .datepickerStyle .labelDiv,
  .datepickerStyle input {
    display: block;
    float: left;
    width: 100%;
    text-align: left;
  }

  .form-control.formLeftSPace {
    max-width: 50% !important;
    margin-top: 28px;
    padding: 0px 10px !important;
    height: 32px;
    font-size: 12px;
  }

  .btn.btn-pink.btn-responsive {
    margin-top: 25px;
    height: 27px !important;
    line-height: 30px !important;
    min-height: 33px;
  }

  .topArea h5 {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }

  .loginComponentMainDiv .loginFormWithLogoDiv {
    width: 90%;
  }

  .loginFormWithLogoDiv .col-lg-3 {
    display: none !important;
  }

  .ag-theme-alpine .ag-header-cell,
  .ag-theme-alpine .ag-header-group-cell,
  .ag-theme-alpine .ag-cell,
  .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .nameAlign {
    font-size: 10px;
    line-height: 13px;
  }

  .nameAlign br {
    display: none;
  }

  .nameAlign small {
    display: block;

  }
}

@media only screen and (max-width:650px) {
  .topAreaScan.row {
    padding: 10px;
  }

  .onBoardScanArea .onBoardScanInner .form-field.form-field2 input {
    font-size: 10px;
  }

  .onBoardScanArea .onBoardScanInner .form-field p svg {
    max-width: 12px;
  }

  .btn,
  .sp-container button {
    min-height: 25px !important;
    line-height: 25px !important;
  }

  .form-field.formfielSubmit .btn.btn-submit {
    margin-top: 6px;
    padding: 4px;
    font-size: 12px;
    border-radius: 8px;
  }

  .ant-select .ant-select-selector {
    height: 30px !important;
    font-size: 12px !important
  }

  .main-content .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  .div100 .div50 {
    padding: 3px;
    font-size: 10px;
  }

  .div100 .div50 span {
    display: inline-block;
    font-size: 10px;
  }

  .topStatsArea .div100 {
    width: 100%;
    margin: 5px 0px -10px 0px;
  }

  .page-header {

    margin: 10px 0px;
  }

  .topAreaScan.row .col-12 {
    margin-bottom: 7px;
  }

  .topAreaScan.row div.col-lg-3 {
    margin-bottom: 2px;
  }

  .topAreaScan.row .col-lg-4:last-child .innerDiv {
    height: auto;
  }

  .onBoardScanArea .onBoardScanInner .form-field p {
    width: 10%;
    margin-bottom: 6px;
  }

  .contentArea {
    margin-top: 10px;
  }

  .loginComponentMainDiv .loginFormWithLogoDiv {
    margin: 0px !important;
  }

  .biggerCountArea {
    font-size: 60px;
  }

  .topArea .col-lg-6.col-6 {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100%;
  }

  .datepickerStyle .react-datepicker-wrapper,
  .datepickerStyle .react-datepicker-wrapper input {
    max-width: 100% !important;
  }

  .topArea .col-lg-3.col-6 {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0px 15px !important;
  }

  .form-control.formLeftSPace {
    margin-top: 5px;
    max-width: 65% !important;
  }


  .btn.btn-pink.btn-responsive {
    margin-top: 5px;
    padding: 0px;
    width: 15% !important;
    min-width: 30%;
    font-size: 12px;
  }

  .topArea h5 {
    font-size: 13px !important;
  }

  .rightDivHeader .hourStyle {
    padding: 6px 4px;
    font-weight: 500;
  }

  .page-header div {
    padding: 0px;
    font-size: 8px;
  }

  .rightDivHeader .hourStyle {
    padding: 6px 4px;
    font-weight: 500;
    font-size: 10px;
  }

  .rightDivHeader {
    min-width: 100px;
  }

  .topStatsArea .row .col-6:last-child {
    padding-left: 5px !important;
  }

  .biggerCountArea {
    font-size: 20px;
    line-height: 10px;
  }

  .smallerTitleArea {
    font-size: 10px;
    margin-top: 8px;
    margin-bottom: 3px;
  }

  .topStatsArea .bgWhite {
    padding: 10px 0px 10px 0px;
  }

  .topStatsArea {
    margin-bottom: 10px;
  }

  .topAreaScan.row {
    padding-bottom: 0px !important;
  }

  .ag-theme-alpine .ag-header-cell,
  .ag-theme-alpine .ag-header-group-cell,
  .ag-theme-alpine .ag-cell,
  .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .nameAlign {
    font-size: 10px;
  }

  .topAreaScan.row .innerDiv {
    padding: 0px 10px;
  }

  .topAreaScan.row .fieldBottom .fieldList {
    font-size: 10px;
    margin-bottom: 3px;
  }

  .onBoardScanArea .onBoardScanInner .form-field {
    font-size: 10px;
  }

  .btn.btn-pink.btn-responsive {
    height: 32px !important;
  }

  .topAreaScan.topAreaScan2.row {
    padding-bottom: 10px !important;
    padding-top: 5px !important;
  }

  .fingerPrintArea {
    text-align: center;
    top: 0;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    height: 30px;
    margin: auto;
    opacity: .3;
  }
}
.loginComponentMainDiv {
    height: 100vh;
    position: relative;
}

.loginComponentMainDiv .firstRowDiv {
    height: 68vh;
    background: #fafafa;
}

.loginComponentMainDiv .secondRowDiv {
    height: 32vh;
    background: #fafafa;
}

.loginComponentMainDiv .loginFormWithLogoDiv {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 20px 10px;
}

@media screen and (min-width: 1200px) {
    .loginComponentMainDiv .loginFormWithLogoDiv {
        min-width: 1020px;
    }
}

.loginComponentMainDiv .loginFormWithLogoDiv .logoDiv {
    text-align: center;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv {
    margin: 2rem 0;
    background: #FFFFFF;
    border-radius: 0.5rem;
    padding: 2.5rem;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv .loginText {
    color: #394584;
    font-size: 1.65rem;
    font-weight: 600;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv .loginForm .eachElement {
    margin: 1rem 0;
    position: relative;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv .loginForm .eachElement img {
    position: absolute;
    top: 2.5rem;
    cursor: pointer;
    right: 0.5rem;
    width: 25px;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv .loginForm .eachElement label {
    color: #394584;
    display: block;
    font-size: 1rem;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv .loginForm .eachElement input {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #8898aa;
    background-color: #fff;
    border: 1px solid #cad1d7;
    border-radius: 0.375rem;
    box-shadow: none;
    outline: none;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv .loginForm .eachElement input:focus {
    outline: none;
    color: #8898aa;
    background-color: #fff;
    border: 1px solid #cad1d7;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv .forgetPasswordText {
    color: #394584;
    font-size: 1rem;
    cursor: pointer;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv .forgetPasswordText:hover {
    text-decoration: underline;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv .loginFormButton {
    margin: 2rem auto 1rem;
    display: block;
    padding: 0.75rem;
    border: none;
    outline: none;
    border-radius: 0.35rem;
    text-align: center;
    background: #0E4ED3;
    min-width: 80%;
    color: #FFFFFF;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv .loginText {
    color: #394584;
    font-size: 1.65rem;
    font-weight: 600;
}

.loginFormMainDiv.loginFormMainDivMobile .loginSmallText {
    font-size: 14px;
    color: #9d9797;
    text-align: center;
}

.loginFormMainDiv.loginFormMainDivMobile label {
    font-size: 13px !important;
    position: absolute;
    top: -8px;
    background: #fff;
    left: 12px;
    padding: 0px 10px;
}

.loginFormMainDiv.loginFormMainDivMobile .loginFormWithLogoDiv .loginFormMainDiv .loginForm .eachElement img {
    top: 10px !important;
}

.loginFormMainDiv.loginFormMainDivMobile .loginForm .eachElement img {
    top: 10px !important;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv.loginFormMainDivMobile .loginForm .eachElement input {
    border-radius: 20px !important;
    padding: 10px 20px !important;
    min-height: 45px !important;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv.loginFormMainDivMobile .loginForm .eachElement input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset !important;
    -webkit-text-fill-color: #000 !important;
    caret-color: #000;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv.loginFormMainDivMobile .loginFormButton {
    margin: 1.5rem auto 1rem;
    text-align: center;
    background: #0E4ED3;
    min-width: 80%;
    color: #FFFFFF;
    border-radius: 20px !important;
}

.loginFormMainDiv.loginFormMainDivMobile .dangerColor {
    color: red !important
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv.loginFormMainDivMobile .loginText {
    color: #394584;
    text-align: center;
    font-size: 26px !important;
}

.loginComponentMainDiv .loginFormWithLogoDiv .loginFormMainDiv.loginFormMainDivMobile {
    background-color: transparent !important;
}
*,
body {
  font-family: 'Sen', sans-serif;
}

.onBoardScanArea .onBoardScanInner .form-field {
  width: 100%;
}

.OnBoardAreaa {
  width: 100%;
  background: #fff;
  padding: 20px;
  padding-top: 60px;
  margin-top: -60px;
}


.onBoardAreaInner {
  float: none;
  margin: 20px auto;
  width: 1000px;
}

.errormessage {
  background: #f7f8f9;
  padding: 2px 20px;
  border-left: 4px solid red;
  border-radius: 4px;
  margin-top: 6px;
  position: absolute;
  width: 100%
}

.sucessMessage {
  background: #f7f8f9;
  padding: 2px 20px;
  border-left: 4px solid green;
  border-radius: 4px;
  margin-top: 6px;
  position: absolute;
  width: 100%
}

.fieldBottom {
  margin: 12px 0px;
}

.fieldBottom .fieldList {
  margin-bottom: 2px;
  font-size: 12px;
}

.contentArea {
  margin-top: 30px;
  background: #fff;
  padding: 40px;
  z-index: 99;
  position: relative;
  border-radius: 8px;
}

.onBoardLeft {
  float: left;
}

.onBoardright {
  float: right;
}

.btn.btn-pink {
  min-width: 100px;
  background: #ef5e8c;
  color: #fff;
}

.breadcrumb-item.active strong {
  color: #e7457b;
}

.ant-modal {
  border-radius: 10px !important;
  overflow: hidden;
}

.ant-btn.ant-btn-primary:hover {
  background: #cc2359;
  border-color: #ef5e8c;
  min-width: 100px;
}

.ant-btn.ant-btn-default:hover {
  color: #e7457b;
  border-color: #ef5e8c;

}

.tableArea {
  width: 100%;
}

.textCapitalize.textCap.ag-cell-value {
  text-transform: capitalize;
}

.topArea {
  float: left;
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.ag-theme-alpine.if.cell-size-40 {
  overflow: hidden;
}



.tableContentArea.row .errorMessage {
  margin-top: 15px;
}


.rightDivHeader div {
  display: inline-block;
}

.rightDivHeader {
  min-width: 200px;
}

.rightDivHeader .hourStyle {
  padding: 9px 20px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 500;
}

.rightDivHeader .btnLogout .btn {
  margin-left: 10px;
  font-size: 13px;
  border-radius: 4px;
  color: #fff;
  width: 40px;
  border: 0px;
  background: #fff;
  padding: 0px;
}

.rightDivHeader .btnLogout .btn img {
  max-width: 15px;
}

.ant-btn.ant-btn-primary {
  background: #ef5e8c;
  border-color: #ef5e8c;
  min-width: 100px;
}

.logoArea img {
  max-width: 100%;
  height: auto;
  width: auto;
}

.datepickerStyle .react-datepicker-wrapper,
.datepickerStyle .react-datepicker-wrapper input {
  max-width: 100px !important;
}

label .datepickerStyle .react-datepicker-wrapper input {
  border: 1px solid #ddd;
  padding: 5px;
  margin: 0px 10px;
}

.datepickerStyle {
  margin-right: 30px;
}

.datepickerStyle .labelDiv,
.datepickerStyle input {
  display: inline-block;
}

.datepickerStyle .react-datepicker-wrapper input {
  margin: 0px 10px;
  padding: 5px 20px;
  font-size: 12px;
  border: 1px solid #ddd;
}

.datepickerStyle {
  display: inline-block;
}

.topStatsArea {
  margin-bottom: 20px;
}

.topStatsArea .bgWhite {
  padding: 10px 60px 30px 60px;
  background: #fff;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0px 2px 8px #e2ecf980;
  overflow: auto;
}

.biggerCountArea {
  font-size: 72px;
  text-align: center;
  color: #170c6b;
}

.smallerTitleArea {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  color: #929699;
}

.statsLeftRight {
  font-size: 13px;
  color: #414c51;
  margin-top: 20px;
}

.smallerSubTitleArea {
  text-align: center;
}

.onBoardScanArea .onBoardScanInner {
  width: 100%;
  float: none;
  margin: 0 auto;

  border-radius: 8px;
  z-index: 99;
  position: relative;
  overflow: hidden;
}

.topAreaScan.row {
  padding: 20px;
  margin: 0px;
  background: #263053;
}

.ScanFieldFilter.row {
  margin: 0px;
  padding: 20px;
  background: #fff;
  margin-top: 15px;
}

.topAreaScan .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #fcfdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #d9d9d940;
}

.topAreaScan.row .fieldBottom .fieldList {
  font-size: 14px;
  margin-bottom: 7px;
}

.topAreaScan.row .col-lg-4:last-child .innerDiv {
  height: 120px;
}

.topAreaScan.row .innerDiv {
  padding: 20px;
  border: 2px solid #ddd;
  height: 100%;
  background: #e9ecf6;
  border-radius: 8px;
}

.form-field.formfielSubmit .btn.btn-submit {
  margin-top: 14px;
  background: #ef5e8c;
  color: #fff;
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
}

.onBoardScanArea .onBoardScanInner .form-field {
  width: 100%;
}

.onBoardScanArea .onBoardScanInner .form-field p {
  width: 40px;
  float: left;
  margin-top: 0px !important;
  background: #fff;
  line-height: 35px;
  padding: 0px;
}

.topAreaScan.row .col-lg-6 label {
  color: #fff;
}

.onBoardScanArea .onBoardScanInner .form-field p svg {
  max-width: 16px;
}

.ant-select.ant-select-single.ant-select-show-arrow.ant-select-disabled {
  opacity: .6;
}

.onBoardScanArea .onBoardScanInner .form-field.form-field2 {
  float: none;
  margin: 0 auto;
  width: 500px;
}

.onBoardScanArea .onBoardScanInner .form-field.form-field2 input {
  width: 400px;
  float: left;
  background: #eaedf7;
}

.Toastify__toast-container--top-right {
  width: auto !important;
  max-width: 400px;
}

.onBoardScanArea .onBoardScanInner .form-field.form-field2 input[disabled] {
  background: #837c7c;
  border-color: #837c7c;
  transition: ease-in .3s;
}

.onBoardScanArea .onBoardScanInner .form-field.form-field2 input[disabled]::placeholder {
  opacity: 1;
  font-size: 13px;
  color: #c4bebe;
}

.noteMessage {
  display: inline-block;
  background: #263053;
  color: #fff;
  padding: 4px 10px;
  font-size: 12px;
}

.ant-modal.logoutModal .ant-modal-header {
  text-transform: uppercase;
  font-weight: bolder;
}

.ant-modal.logoutModal {
  height: 245px;
}

.ant-modal.logoutModal .ant-modal-footer {
  text-align: center;
  height: 70px;
}

.ant-modal.logoutModal .ant-modal-body {
  padding: 15px 21px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.ant-modal.logoutModal .ant-modal-footer button {
  min-width: 47% !important;
  height: 45px;
  border-radius: 12px;
}

.ant-modal.logoutModal.logoutModalHieght2 {
  height: 450px;
  overflow: visible;
  border-radius: 10px !important;
  background: #ffebeb !important;
  padding: 4px;
}

.ant-modal.logoutModal.logoutModalHieght2.logoutModalHieght3 {
  height: 470px;
}

.ant-modal.logoutModal.logoutModalHieght2 .warning-img {
  max-width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-bottom: 30px;
}

.ant-modal.logoutModal.logoutModalHieght2 .ant-modal-content {
  background-color: #ffebeb;
}

.ant-modal.logoutModal.logoutModalHieght2 .ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #ffebeb;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.ant-modal.logoutModal.logoutModalHieght2 .ant-modal-content {
  background-color: #ffebeb;
  box-shadow: unset !important;
}

.modalHeaderCustom {
  text-align: center;
}

.ant-modal.logoutModal.logoutModalHieght2 p strong {
  font-size: 16px;
}

.shiftShort {
  font-size: 24px;
  text-align: center;
  color: #c02020;
  margin-top: -20px;
}

.shiftShort small {
  display: block;
  font-size: 14px;
  line-height: 1;
  color: #000;
}

.smallTaglineResp {
  display: none;
}


.div100 {
  float: left;
  width: calc(100% + 120px);
  margin: 0px -60px;
  margin-top: 20px;
  margin-bottom: -30px;
}

.smallLanguage .ant-select-selector {
  padding: 0px 10px !important;
  min-height: 10px !important;
  height: 30px !important;
  border: 0px !important;
  border-radius: 4px !important;
  text-align: left;
  font-size: 13px !important;
}

.div100 .div50 {
  width: 50%;
  float: left;
  text-align: center;
  padding: 6px;
  font-size: 16px;
  background: #f7f7f7;
}

.div100 .div50:first-child {
  border-right: 1px solid #e0e0e0
}

.div100 .div50 span {
  display: block;
  font-weight: bold;
  font-size: 24px;
}

.div100 .div50:first-child {
  background: #f7f7f7;
}

.div100 .div50:first-child span {
  color: #047204;

}

.div100 .div50:last-child span {
  color: red;
}

/* ---- Responsive start ------ */
@media only screen and (max-width: 1400px) {}

/*Small Desktop */
@media only screen and (max-width: 1200px) {}


@media only screen and (max-width: 1023px) and (min-width:992px) {}


@media only screen and (max-width:992px) {}


@media only screen and (max-width:767px) {
  .topStatsArea .bgWhite {
    padding: 10px 0px 30px 0px;
  }

  .topStatsArea .div100 {
    width: 100%;
    margin: 20px 0px -10px 0px;
  }

  .div100 .div50 span {
    display: inline-block;
    font-size: 12px;
  }

  .biggerCountArea {
    font-size: 60px;
  }

  .smallerTitleArea {
    font-size: 16px;
  }

  .onBoardScanArea .onBoardScanInner .form-field.form-field2 {
    width: 100%;
  }

  .onBoardScanArea .onBoardScanInner .form-field.form-field2 input {
    width: 70%;
  }

  .onBoardScanArea .onBoardScanInner .form-field p {
    width: 12%;
  }

  .topAreaScan.row .col-12 {
    margin-bottom: 15px;
  }

  .topAreaScan.row {
    padding-bottom: 0px;
  }

  .hideResp {
    display: none;
  }

  .page-header div {
    padding: 0px;
  }

  .smallTaglineResp {
    display: block;
  }

  .topAreaScan.row div {
    padding-left: 0px;
    padding-right: 0px;
  }

  .main-content .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  .topStatsArea .row .col-6:first-child {
    padding-right: 0px;
  }

  .page-header {
    margin: 10px 0px;
    padding: 10px;
    background-color: #fff;
  }

  .datepickerStyle {
    width: calc(50% - 10px);
    float: left;
    margin-right: 10px;
  }

  .datepickerStyle .react-datepicker-wrapper,
  .datepickerStyle .react-datepicker-wrapper input {
    width: 100%;
    margin: 0px;
  }

  .noteMessage {
    display: none;
  }

  .contentArea {
    margin-top: 20px;
    padding: 12px;
  }

  .datepickerStyle .labelDiv,
  .datepickerStyle input {
    display: block;
    float: left;
    width: 100%;
    text-align: left;
  }

  .form-control.formLeftSPace {
    max-width: 50% !important;
    margin-top: 28px;
    padding: 0px 10px !important;
    height: 32px;
    font-size: 12px;
  }

  .btn.btn-pink.btn-responsive {
    margin-top: 25px;
    height: 27px !important;
    line-height: 30px !important;
    min-height: 33px;
  }

  .topArea h5 {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }

  .loginComponentMainDiv .loginFormWithLogoDiv {
    width: 90%;
  }

  .loginFormWithLogoDiv .col-lg-3 {
    display: none !important;
  }

  .ag-theme-alpine .ag-header-cell,
  .ag-theme-alpine .ag-header-group-cell,
  .ag-theme-alpine .ag-cell,
  .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .nameAlign {
    font-size: 10px;
    line-height: 13px;
  }

  .nameAlign br {
    display: none;
  }

  .nameAlign small {
    display: block;

  }
}

@media only screen and (max-width:650px) {
  .topAreaScan.row {
    padding: 10px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 18px !important;
  }

  .smallLanguage .ant-select-single .ant-select-selector .ant-select-selection-item,
  .smallLanguage .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 32px !important;
  }

  .onBoardScanArea .onBoardScanInner .form-field.form-field2 input {
    font-size: 10px;
  }

  .onBoardScanArea .onBoardScanInner .form-field p svg {
    max-width: 12px;
  }

  .btn,
  .sp-container button {
    min-height: 25px !important;
    line-height: 25px !important;
  }

  .form-field.formfielSubmit .btn.btn-submit {
    margin-top: 6px;
    padding: 4px;
    font-size: 12px;
    border-radius: 8px;
  }

  .ant-select .ant-select-selector {
    height: 30px !important;
    font-size: 12px !important
  }

  .main-content .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  .div100 .div50 {
    padding: 3px;
    font-size: 10px;
  }

  .div100 .div50 span {
    display: inline-block;
    font-size: 10px;
  }

  .topStatsArea .div100 {
    width: 100%;
    margin: 5px 0px -10px 0px;
  }

  .page-header {

    margin: 10px 0px;
  }

  .topAreaScan.row .col-12 {
    margin-bottom: 7px;
  }

  .topAreaScan.row div.col-lg-3 {
    margin-bottom: 2px;
  }

  .topAreaScan.row .col-lg-4:last-child .innerDiv {
    height: auto;
  }

  .onBoardScanArea .onBoardScanInner .form-field p {
    width: 10%;
    margin-bottom: 6px;
  }

  .contentArea {
    margin-top: 10px;
  }

  .loginComponentMainDiv .loginFormWithLogoDiv {
    margin: 0px !important;
  }

  .biggerCountArea {
    font-size: 60px;
  }

  .topArea .col-lg-6.col-6 {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100%;
  }

  .datepickerStyle .react-datepicker-wrapper,
  .datepickerStyle .react-datepicker-wrapper input {
    max-width: 100% !important;
  }

  .topArea .col-lg-3.col-6 {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0px 15px !important;
  }

  .form-control.formLeftSPace {
    margin-top: 5px;
    max-width: 65% !important;
  }


  .btn.btn-pink.btn-responsive {
    margin-top: 5px;
    padding: 0px;
    width: 15% !important;
    min-width: 30%;
    font-size: 12px;
  }

  .topArea h5 {
    font-size: 13px !important;
  }

  .rightDivHeader .hourStyle {
    padding: 6px 4px;
    font-weight: 500;
  }

  .page-header div {
    padding: 0px;
    font-size: 8px;
  }

  .rightDivHeader .hourStyle {
    padding: 6px 4px;
    font-weight: 500;
    font-size: 10px;
  }

  .rightDivHeader {
    min-width: 100px;
  }

  .topStatsArea .row .col-6:last-child {
    padding-left: 5px !important;
  }

  .biggerCountArea {
    font-size: 20px;
    line-height: 10px;
  }

  .smallerTitleArea {
    font-size: 10px;
    margin-top: 8px;
    margin-bottom: 3px;
  }

  .topStatsArea .bgWhite {
    padding: 10px 0px 10px 0px;
  }

  .topStatsArea {
    margin-bottom: 10px;
  }

  .topAreaScan.row {
    padding-bottom: 0px !important;
  }

  .ag-theme-alpine .ag-header-cell,
  .ag-theme-alpine .ag-header-group-cell,
  .ag-theme-alpine .ag-cell,
  .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .nameAlign {
    font-size: 10px;
  }

  .topAreaScan.row .innerDiv {
    padding: 0px 10px;
  }

  .topAreaScan.row .fieldBottom .fieldList {
    font-size: 10px;
    margin-bottom: 3px;
  }

  .onBoardScanArea .onBoardScanInner .form-field {
    font-size: 10px;
  }

  .btn.btn-pink.btn-responsive {
    height: 32px !important;
  }
}


/* Dashboard Mobile */
.main-content.main-contentMobile .page-header.row {
  padding: 0px;
  background: transparent !important;
  margin-bottom: 1px !important;
}

.firstClickArea {
  position: absolute;
  left: 0;
  right: 0;
  top: -10px;
  bottom: 0;
  z-index: 999;
  background-color: rgb(255, 255, 255, .9);
  height: 100%;
  cursor: pointer;
}

.onboardInnerStart {
  position: absolute;
  /* height: 120px; */
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  /* margin: 20px 10px; */
}

.onboardInnerStart img {
  max-width: 130px;
  margin: 10px 0px;
}

.onboardInnerStart h2 {
  font-size: 22px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 0px;
}

.onboardInnerStart p {
  color: #343649;
}

.videoCanvasContainer {
  position: relative;

  /* Adjust to fit the content width */
}

.videoCanvasContainer video {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: auto;
}

.videoCanvasContainer canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  /* Ensure the canvas is on top */
}

body.main-body.leftmenu.notStarted {
  background: rgb(255, 197, 197);
}
