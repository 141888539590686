.successToast{
  background: #00FF00 !important;
  color: #FFFFFF !important;
}

.errorToast{
  background: #ff0000 !important;
  color: #FFFFFF !important;
}

.ReactModal__Overlay{
  background: rgba(0,0,0,0.5) !important;
  box-shadow: none !important;
}

*{box-sizing: border-box;}
.ag-cell,.ag-body-viewport.ag-selectable.ag-layout-normal.ag-row-no-animation,.ag-body-viewport.ag-selectable.ag-layout-normal.ag-row-no-animation * {
  user-select: initial;
  -moz-user-select: text;
  -ms-user-select: text;
  -webkit-user-select: text;
}